import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomePage } from './pages';
import { CategoriesWModule } from '@widgets/categories';
import { RecentUpdatesWModule } from '@widgets/recent-updates';
import { CardsModule } from '@widgets/cards';


import { CustomCarouselModule } from '@widgets/carousel/carousel.module';
import { EcHomeCarouselComponent } from './components';

import { CallToActionModule } from '@widgets/call-to-action';

@NgModule({
    imports: [
    CommonModule,
    HomeRoutingModule,
    CardsModule,
    CategoriesWModule,
    RecentUpdatesWModule,
    CustomCarouselModule,
    CallToActionModule,
    HomePage, EcHomeCarouselComponent,
],
    exports: [HomePage],
})
export class HomeModule {}
