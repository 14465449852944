import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { SafeUrlPipe } from '@widgets/safe/pipes';
import { PlayerCoverImgComponent } from '../player-cover-img/player-cover-img.component';

@Component({
    selector: 'ec-video-player',
    template: `
    <div
      class="wrapper"
      ec-player-cover-img
      [url]="coverImg"
      [borderRadius]="borderRadius"
      (play$)="playEmitted(videoElement)"
    >
      <video controls [src]="link | safeUrl" type="video/mp4" #videoElement></video>
    </div>
  `,
    styleUrls: ['./video-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PlayerCoverImgComponent, SafeUrlPipe],
})
export class VideoPlayerComponent {
  @Input() link!: string;

  @Input() coverImg!: string;

  @Input() borderRadius = 0;

  @Output() playClicked: EventEmitter<unknown> = new EventEmitter<unknown>();

  public playEmitted(videoElement: HTMLVideoElement): void {
    this.playClicked.emit();
    void videoElement.play();
  }

  // @Input() height!: number | undefined;

  // @Input() width!: number | undefined;
}
