import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ICollapsibleMenuItem, IMenuItem, MenuItems, MenuTriggerState } from '@core/menu';
import { ID } from '@datorama/akita';
import { AbsComponentWithEnvironmentData } from '@base/abs';
import { Router, RouterLink } from '@angular/router';
import { Icon } from '@widgets/icons';
import { PROFILE_MENU_ITEMS } from '@core/menu/const/profile-items.const';
import { AccountService } from '@services/account';
import { ROUTES } from '@const';
import { Observable, delay, filter } from 'rxjs';
import { LayoutService } from '../../services';
import { ResponsiveService } from '../../../../services/responsive';
import { AndroidAppComponent } from '../../../../widgets/apps/components/android-app/android-app.component';
import { IosAppComponent } from '../../../../widgets/apps/components/ios-app/ios-app.component';
import { ButtonComponent } from '../../../../widgets/buttons/components/button/button.component';
import { MenuTriggerComponent } from '../../../menu/components/menu-trigger/menu-trigger.component';
import { BadgeDirective } from '../../../../widgets/badge/directives/badge.directive';
import { IconWrapperComponent } from '../../../../widgets/icons/components/icon-wrapper/icon-wrapper.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MainLogoComponent } from '../../../../widgets/logos/components/main-logo/main-logo.component';

@Component({
    selector: 'div[ec-mobile-header]',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [
        MainLogoComponent,
        NgIf,
        IconWrapperComponent,
        RouterLink,
        BadgeDirective,
        MenuTriggerComponent,
        NgFor,
        NgClass,
        ButtonComponent,
        IosAppComponent,
        AndroidAppComponent,
        AsyncPipe,
    ],
})
export class MobileHeaderComponent
  extends AbsComponentWithEnvironmentData
  implements OnChanges, OnInit
{
  @Input() triggerState!: MenuTriggerState | null;

  @Input() menuItems!: MenuItems | null;

  @Input() isAuthorized: boolean | null = false;

  @Input() notCompletedRegistration: boolean | null = false;

  @Output() triggerClicked: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() register: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() login: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() logout: EventEmitter<unknown> = new EventEmitter<unknown>();

  collapsibleMenuItems!: ICollapsibleMenuItem[];

  profileMenuItem = PROFILE_MENU_ITEMS;

  public triggerState$: Observable<MenuTriggerState> = this.layoutService.triggerState$;

  constructor(
    public readonly accountService: AccountService,
    private readonly router: Router,
    protected layoutService: LayoutService,
    public readonly responsiveService: ResponsiveService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setSnippet();
    this.accountService.account$.subscribe(account => {
      if (account.accreditation_status !== 'Accredited') {
        this.profileMenuItem.child = this.profileMenuItem.child?.filter(
          child => child.label !== 'Documents & Reports',
        );
      }

      this.profileMenuItem.child?.forEach(d => {
        if (d.route === `/${ROUTES.profile}/accounts`) {
          d.label = `Accounts (${
            (account.count_accounts || 0) + (account.count_shared_accounts || 0)
          })`;
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuItems) {
      this.collapsibleMenuItems = changes.menuItems.currentValue?.map((item: IMenuItem) => ({
        ...item,
        collapsed: true,
      }));
    }
  }

  getIcon(menuItem: ICollapsibleMenuItem): Icon {
    return menuItem.collapsed ? 'arrow-down' : 'arrow-up_active';
  }

  opened(): boolean {
    return this.triggerState === MenuTriggerState.opened;
  }

  handleNavigationClick(menuItem: ICollapsibleMenuItem): void {
    if (menuItem.externalLink) {
      window.open(menuItem.externalLink, '_self');
    } else {
      void this.router.navigate([menuItem.route]);
    }
    this.triggerClicked.emit();
  }

  handleLogout(): void {
    this.logout.emit();
    this.triggerClicked.emit();
  }

  trackMenus(index: number, menuItem: IMenuItem): ID {
    return menuItem.id;
  }

  setSnippet(): void {
    this.triggerState$
      .pipe(
        filter(triggerState => triggerState === MenuTriggerState.opened),
        delay(100),
      )
      .subscribe(() => {
        const appendElem = document.getElementsByClassName('menu-snippet')[0];
        const snippet = document.getElementById('snippet')?.cloneNode(true) as HTMLDivElement;
        snippet.classList.add('snippet');
        if (!!appendElem && !!snippet) {
          appendElem.appendChild(snippet);
        }
      });
  }
}
