import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecentUpdateSectionComponent } from './components';

import { CardsModule } from '../cards';
import { ContentWModule } from '../content';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';



@NgModule({
    imports: [
    CommonModule,
    CardsModule,
    ContentWModule,
    InfiniteScrollModule,
    RecentUpdateSectionComponent,
],
    exports: [RecentUpdateSectionComponent],
})
export class RecentUpdatesWModule {}
