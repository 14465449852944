import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { ICategory } from '@services/categories';
import { AbsComponentWithVisibilityCheck } from '@base/abs';
import { AuthService } from '@services/auth';
import { Router } from '@angular/router';
import { ROUTES } from '@const';
import { ResponsiveService } from '@services/responsive';
import { Observable } from 'rxjs';
import { LockIconDirective } from '@widgets/content/directives';
import { takeUntil } from 'rxjs/operators';
import { CategoryPropPipe } from '../../pipes/categoryProp.pipe';
import { UnlockContentComponent } from '../../../restriction-messages/components/unlock-content/unlock-content.component';
import { LockIconDirective as LockIconDirective_1 } from '../../../content/directives/lock-icon.directive';
import { PopoverComponent } from '../../../dropdown/components/popover/popover.component';
import { TextComponent } from '../../../typography/components/text/text.component';
import { TextShortenerDirective } from '../../../typography/directives/text-shortener.directive';
import { CategoryCardOverlayComponent } from '../category-card-overlay/category-card-overlay.component';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'div[ec-category-card-content]',
    templateUrl: './category-card-content.component.html',
    styleUrls: ['./category-card-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        CategoryCardOverlayComponent,
        TextShortenerDirective,
        TextComponent,
        PopoverComponent,
        LockIconDirective_1,
        UnlockContentComponent,
        AsyncPipe,
        CategoryPropPipe,
    ],
})
export class CategoryCardContentComponent
  extends AbsComponentWithVisibilityCheck<ICategory>
  implements AfterViewInit
{
  @Input() withImage!: boolean;

  @Input() category!: ICategory;

  @ViewChild(LockIconDirective) lockIcon!: LockIconDirective;

  @ViewChild('popOverToggle') popOverToggle!: ElementRef<HTMLButtonElement>;

  public isMobile$: Observable<boolean> = this.responsiveService.isMobile$;

  popOverActive = false;

  constructor(
    authService: AuthService,
    private readonly router: Router,
    private readonly responsiveService: ResponsiveService,
  ) {
    super(authService);
  }

  @HostListener('click', ['$event'])
  onClick($event: PointerEvent) {
    if ($event.ctrlKey || $event.metaKey) {
      $event.preventDefault();
      $event.stopPropagation();
      const url = this.getNavigationURL();
      window.open(url);
    } else {
      this.handleNavigation();
    }
  }

  ngAfterViewInit(): void {
    this.lockIcon?.hover$.pipe(takeUntil(this.destroyed$)).subscribe((popOverActive: boolean) => {
      if (popOverActive && !this.popOverActive) {
        this.popOverToggle?.nativeElement?.click();
        this.popOverActive = popOverActive;
      }
      if (!popOverActive && this.popOverActive) {
        this.popOverToggle?.nativeElement?.click();
        this.popOverActive = popOverActive;
      }
    });
  }

  handleNavigation(): void {
    this.router
      .navigate([this.getNavigationURL()], {
        skipLocationChange: false,
      })
      .then();
  }

  handleOnShown(): void {
    this.popOverActive = true;
    this.lockIcon.setValue(true);
  }

  handleOnHidden(): void {
    this.popOverActive = false;
    this.lockIcon.setValue(false);
  }

  private getNavigationURL(): string {
    return `./${ROUTES.learn}/${ROUTES.categories}/${this.category.id}`;
  }
}
