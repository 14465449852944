import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsComponentWithLayoutChange } from '@base/abs';
import { BackgroundValues, LayoutService } from '@core/layout';
import { GetContentDto } from '@services/content';
import { IContentSectionProps, SectionLabel } from '@widgets/content-sections';
import { Observable, of, take, tap } from 'rxjs';
import { MenuService } from '@core/menu/services';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth';
import { ResponsiveService } from '../../../../services/responsive';
import { AsyncPipe } from '@angular/common';
import { InvestActionCallComponent } from '../../../../widgets/call-to-action/components/invest-action-call/invest-action-call.component';
import { RecentUpdateSectionComponent } from '../../../../widgets/recent-updates/components/recent-update-section/recent-update-section.component';
import { CategoriesViewWrapperComponent } from '../../../../widgets/categories/components/categories-view-wrapper/categories-view-wrapper.component';
import { PageComponent } from '../../../../widgets/page/components/page/page.component';
import { EcHomeCarouselComponent } from '../../components/ec-home-carouself/ec-home-carousel.component';

@Component({
    selector: 'ec-home',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        EcHomeCarouselComponent,
        PageComponent,
        CategoriesViewWrapperComponent,
        RecentUpdateSectionComponent,
        InvestActionCallComponent,
        AsyncPipe,
    ],
})
export class HomePage extends AbsComponentWithLayoutChange implements AfterViewInit {
  public isAuthorized$: Observable<boolean> = this.authService.isAuthorized$;

  public accreditationExpired: boolean =
    this.authService.getAccount().accreditation_expired || false;

  constructor(
    layoutService: LayoutService,
    private readonly authService: AuthService,
    private readonly menuService: MenuService,
    private readonly router: Router,
    private readonly responsiveService: ResponsiveService,
  ) {
    super(layoutService, {
      background: BackgroundValues.grey,
      elements: { slider: true, footer: true, wrapper: false },
    });
    menuService.setHeaderState(true);
    if (this.router.url === '/home') {
      void this.router.navigate(['/learn']);
    }
  }

  ngAfterViewInit(): void {
    this.responsiveService.isTouch$
      .pipe(
        take(1),
        tap(isTouch => (!isTouch ? this.setSnippet() : null)),
      )
      .subscribe();
  }

  get contentProps(): Observable<IContentSectionProps> {
    return of({ label: SectionLabel.recentUpdates, actionLabel: 'All Posts' });
  }

  get contentDto(): Observable<GetContentDto> {
    return of(new GetContentDto({ page: 1, page_size: 9 }));
  }
}
