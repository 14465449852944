import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsModalComponent } from '@widgets/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../../../widgets/buttons/components/button/button.component';
import { SuccessNotificationComponent } from '../../../../widgets/notification/components/success-notification/success-notification.component';
import { ModalHeaderComponent } from '../../../../widgets/modal/components/modal-header/modal-header.component';

@Component({
    selector: 'ec-link-expired',
    templateUrl: './link-expired.modal.html',
    styleUrls: ['./link-expired.modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalHeaderComponent,
        SuccessNotificationComponent,
        ButtonComponent,
    ],
})
export class LinkExpiredModal extends AbsModalComponent {
  constructor(public activeModal: NgbActiveModal) {
    super(activeModal);
  }
}
