import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SliderService } from '@services/slider';
import { Observable } from 'rxjs';
import { ISliderItem } from '@services/slider/interfaces';
import { AsyncPipe } from '@angular/common';
import { CarouselWrapperComponent } from '../../../../widgets/carousel/components/carousel-wrapper/carousel-wrapper.component';

@Component({
    selector: 'ec-ec-home-carousel',
    templateUrl: './ec-home-carousel.component.html',
    styleUrls: ['./ec-home-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CarouselWrapperComponent, AsyncPipe],
})
export class EcHomeCarouselComponent {
  public items$: Observable<ISliderItem[]>;

  constructor(private readonly sliderService: SliderService) {
    this.items$ = this.sliderService.sliderItems$;
  }
}
