<div class="modal-wrapper">
  <div ec-modal-header heading="Accreditation Expired"></div>
  <div class="modal-body">
    <ec-text>Update your accreditation to maintain full access. </ec-text>
  </div>
  <div class="modal-footer">
    <button ec-button color="white" (click)="activeModal.close()">Close</button>
    <ng-container>
      <button
        [disabled]="
          !!(landingService.countDownTimer | async) ||
          !!(landingService.accreditationLinkIsLoading | async)
        "
        ec-button
        color="primary"
        (click)="goToPandaDoc()"
      >
        <span
          *ngIf="landingService.accreditationLinkIsLoading | async"
          class="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></span>
        @if(!!(landingService.countDownTimer | async)) { New Link available in {{
        landingService.countDownTimer | async }} } @else { Update Accreditation }
      </button>
    </ng-container>
  </div>
</div>
