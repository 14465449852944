import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ec-base-card',
    template: `
    <ng-container>
      <ng-content></ng-content>
    </ng-container>
  `,
    styleUrls: ['./base-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class BaseCardComponent {}
