import { MenuItems } from '../types';
import { guid } from '@datorama/akita';
import { ROUTES } from '@const';
import { MenuItemLabel } from '../enums';

const learnChild = [
  {
    id: guid(),
    label: MenuItemLabel.overview,
    route: `/${ROUTES.learn}/${ROUTES.overview}`,
  },
  {
    id: guid(),
    label: MenuItemLabel.allCategories,
    route: `/${ROUTES.learn}/${ROUTES.categories}`,
  },
  {
    id: guid(),
    label: MenuItemLabel.introToMinerals,
    route: `/${ROUTES.learn}/${ROUTES.mineralIntro}`,
  },
  {
    id: guid(),
    label: MenuItemLabel.featured,
    route: `/${ROUTES.learn}/${ROUTES.featuredStories}`,
  },
  {
    id: guid(),
    label: MenuItemLabel.recentUpdates,
    route: `/${ROUTES.learn}/${ROUTES.recentUpdates}`,
  },
];

const ACCOUNT_ITEM: MenuItems = [
  {
    id: guid(),
    label: MenuItemLabel.assets,
    route: `/${ROUTES.assets}`,
  },
];

const P2P_ITEM: MenuItems = [
  {
    id: guid(),
    label: MenuItemLabel.p2p,
    route: `/${ROUTES.p2p}`,
  },
];

const MENU_ITEMS: MenuItems = [
  {
    id: guid(),
    label: MenuItemLabel.howItWorks,
    externalLink: 'https://eckardenterprises.com/become-an-eckard-partner',
  },
  {
    id: guid(),
    label: MenuItemLabel.company,
    externalLink: 'https://eckardenterprises.com/about-us',
  },
  {
    id: guid(),
    label: MenuItemLabel.learn,
    route: `/${ROUTES.learn}`,
    child: learnChild,
  },
  {
    id: guid(),
    label: MenuItemLabel.market,
    route: `/${ROUTES.market}`,
  },
];

const GUEST_MENU_ITEMS: MenuItems = [
  {
    id: guid(),
    label: MenuItemLabel.howItWorks,
    externalLink: 'https://eckardenterprises.com/become-an-eckard-partner',
  },
  {
    id: guid(),
    label: MenuItemLabel.company,
    externalLink: 'https://eckardenterprises.com/about-us',
  },
  {
    id: guid(),
    label: MenuItemLabel.learn,
    route: `/${ROUTES.learn}`,
    child: learnChild,
  },
  {
    id: guid(),
    label: MenuItemLabel.market,
    route: `/${ROUTES.landing}`,
  },
];

export { MENU_ITEMS, GUEST_MENU_ITEMS, ACCOUNT_ITEM, P2P_ITEM };
