import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'div[ec-modal-header]',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        InlineSVGModule,
    ],
})
export class ModalHeaderComponent {
  @Input() heading!: string;

  @Input() hideCloseButton!: boolean;

  @Input() customClass: 'centered' | 'centered small' | 'small' | undefined;

  @Input() subHeading?: string;

  @Input() customClose: boolean;

  @Output() closeClicked: EventEmitter<void> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}
}
