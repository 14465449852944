import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CategoriesService } from '@services/categories';
import { AbsComponentWithCategories } from '@base/abs';
import { BasePaginationDTO } from '@base/classes';
import { Observable, Subject } from 'rxjs';
import { IContentSectionProps } from '@widgets/content-sections';
import { WithScrollHandler, WithSectionProps } from '@base/interfaces';
import { ID } from '@datorama/akita';
import { Router } from '@angular/router';
import { ROUTES } from '@const';
import { LoadingService } from '@core/backend/services/loading.service';
import { debounceTime } from 'rxjs/operators';
import { SpinnerWrapperComponent } from '../../../../widgets/spinner/components/spinner-wrapper/spinner-wrapper.component';
import { LearnCategoryItemsComponent } from '../learn-category-items/learn-category-items.component';
import { ContentSectionComponent } from '../../../../widgets/content-sections/components/content-section/content-section.component';
import { UnlockContentOverlayComponent } from '../../../../widgets/restriction-messages/components/unlock-content-overlay/unlock-content-overlay.component';
import { NgFor, AsyncPipe } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
    selector: 'ec-learn-categories-wrapper',
    templateUrl: './learn-categories-wrapper.component.html',
    styleUrls: ['./learn-categories-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        InfiniteScrollDirective,
        NgFor,
        UnlockContentOverlayComponent,
        ContentSectionComponent,
        LearnCategoryItemsComponent,
        SpinnerWrapperComponent,
        AsyncPipe,
    ],
})
export class LearnCategoriesWrapperComponent
  extends AbsComponentWithCategories<BasePaginationDTO>
  implements Pick<WithSectionProps, 'handleActionClick'>, WithScrollHandler
{
  public categoriesSections$: Observable<IContentSectionProps[]> =
    this.categoriesService.getSectionProps$(this.categories$);

  debounced$ = new Subject();

  constructor(
    categoriesService: CategoriesService,
    private readonly router: Router,
    public readonly loadingService: LoadingService,
  ) {
    super(categoriesService, new BasePaginationDTO({ page: 1, page_size: 5 }), {
      showCount: undefined,
    });
    this.getData();

    this.debounced$.pipe(debounceTime(1000)).subscribe(() => {
      this.handleNextData();

      if (this.hasNext) {
        this.categoriesSections$ = this.categoriesService.getSectionProps$(this.categories$);
      }
    });
  }

  handleActionClick(props: IContentSectionProps): void {
    this.router.navigate([`${ROUTES.learn}/${ROUTES.categories}`, props.content?.id]).then();
  }

  handleScroll(): void {
    this.debounced$.next(true);
  }

  trackByCategorySection(index: number, section: IContentSectionProps): ID {
    return section.label;
  }
}
