import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * Generated class for the SafeUrl pipe.
 *
 */
@Pipe({
    name: 'safeUrl',
    standalone: true,
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(url: string | null): SafeUrl | null {
    if (!url) {
      return null;
    }

    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
