import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ec-text',
    template: `
    <div
      class="text text-size--{{ size }} text-touch-size--{{ touchSize }} text-color--{{
        color
      }} text-font--{{ font }} text-weight--{{ weight }} max-{{ lineClamp }}"
      [class.adapt-small-desktop]="adaptSmallDesktop"
      [class.text-cut]="!!lineClamp"
      [style.white-space]="whiteSpace"
      [style.text-align]="textAlign"
      [style.text-decoration]="decoration"
    >
      <ng-content></ng-content>
    </div>
  `,
    styleUrls: ['./text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class TextComponent {
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xlg' | 'xl' | 'xxl' = 'md';

  @Input() touchSize?: 'sm' | 'md' | 'lg' | 'xl' = 'md';

  @Input() adaptSmallDesktop: boolean;

  @Input() color: 'default' | 'primary' | 'secondary' | 'danger' | 'gray' | 'success' | 'green' | 'white' =
    'default';

  @Input() weight: '400' | '600' | '500' = '500';

  @Input() font: 'raleway' | 'montserrat' = 'montserrat';

  @Input() whiteSpace: 'pre-line' | 'nowrap' |  null;

  @Input() lineClamp!: number | string;

  @Input() textAlign: 'start' | 'center' | 'end' = 'start';

  @Input() decoration: 'underline' | 'none' = 'none';
}
