<div
  class="unlock-content {{ position }}"
  [ngClass]="{ 'unlock-content--overlay': overlay, 'is-white': whiteOverlay }"
>
  <div [class.wrapper]="overlay">
    <div class="unlock-content-header">
      <div class="unlock-content-header-icon">
        <img class="icon-lock" src="assets/icons/rounded_lock.svg" alt="Restricted" />
      </div>
      <div class="unlock-content-header-title">
        <ng-container *ngIf="isAuthorized$ | async; then accreditationBlock; else loginBlock">
        </ng-container>
        <ng-template #loginBlock>
          <p>Sign up or log in to get access to all exclusive content</p>
        </ng-template>
        <ng-template #accreditationBlock>
          <ng-container
            *ngIf="
              accountService.account.accreditation_status === AccreditationStatus.pending_email;
              else pendingAccreditationStatus
            "
          >
            <p>You need to be accredited investor to see the content</p>
          </ng-container>
          <ng-template #pendingAccreditationStatus>
            <ng-container
              *ngIf="accountService.account.accreditation_status === AccreditationStatus.pending; else notPendingEmailStatus"
            >
              Your account is under review. We will notify you via email once your account has been approved (verified) and you will be granted access to all exclusive content.
            </ng-container>
          </ng-template>
          <ng-template #notPendingEmailStatus>
            <p>Submit accreditation form to get access to all exclusive content</p>
            <button
              [disabled]="
                !!(landingService.countDownTimer | async) ||
                !!(landingService.accreditationLinkIsLoading | async)
              "
              (click)="goToPandaDoc()"
              class="mt-3"
              ec-button
              color="primary"
            >
              <span
                *ngIf="landingService.accreditationLinkIsLoading | async"
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              @if(!!(landingService.countDownTimer | async)) { New Link available in
              {{ landingService.countDownTimer | async }}
              } @else { Update Accreditation }
            </button>
          </ng-template>
        </ng-template>
      </div>
    </div>
    <div class="unlock-content-footer" *ngIf="(isAuthorized$ | async) === false">
      <a
        ec-button
        color="white"
        size="std"
        routerLink="/auth/login"
        (click)="modalService.dismissAll()"
      >
        Log In
      </a>

      <a ec-button size="std" routerLink="/auth/register" (click)="modalService.dismissAll()">
        Register for Free
      </a>
    </div>
  </div>
</div>
