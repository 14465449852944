import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { ContentType, IContent } from '@services/learn';
import { AbsComponentWithVisibilityCheck } from '@base/abs';
import { AuthService } from '@services/auth';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ROUTES } from '@const';
import { ID } from '@datorama/akita';
import { ROUTING_PARAMS_KEY } from '../../../../routing/content/const';
import { mineralIntroIdConst } from '../../../../../const/mineral-intro-id.const';
import { Icon } from '@widgets/icons';
import { ContentPipe } from '../../pipes/content.pipe';
import { UppercasePipe } from '../../../typography/pipes/uppercase.pipe';
import { TextShortenerDirective } from '../../../typography/directives/text-shortener.directive';
import { IconWrapperComponent } from '../../../icons/components/icon-wrapper/icon-wrapper.component';
import { NgIf, UpperCasePipe } from '@angular/common';
import { ContentOverlayComponent } from '../content-overlay/content-overlay.component';

@Component({
    selector: 'div[ec-content-card]',
    templateUrl: './content-card.component.html',
    styleUrls: ['./content-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ContentOverlayComponent,
        NgIf,
        IconWrapperComponent,
        TextShortenerDirective,
        UpperCasePipe,
        UppercasePipe,
        ContentPipe,
    ],
})
export class ContentCardComponent extends AbsComponentWithVisibilityCheck<IContent> {
  @Input() content!: IContent;

  @Input() isRelatedPage!: boolean;

  @Input() verticalScrollable!: boolean | null;

  currentPageId: ID | undefined;

  constructor(
    authService: AuthService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    super(authService);
    route.params.subscribe(
      (params: Params) => (this.currentPageId = params[ROUTING_PARAMS_KEY.contentId]),
    );
  }

  @HostListener('click', ['$event'])
  onClick($event: PointerEvent) {
    if ($event.ctrlKey || $event.metaKey) {
      $event.preventDefault();
      $event.stopPropagation();
      const url = this.getNavigationURL();
      const origin = window.origin;
      window.open(`${origin}${url}`);
    } else {
      this.handleNavigation();
    }
  }

  public getContentIcon(type: ContentType): Icon {
    switch (type) {
      case ContentType.article:
        return 'article';
      case ContentType.podcast:
        return 'microphone_black';
      case ContentType.video:
        return 'play_black';
      case ContentType.webinar:
        return 'play_black';
    }
  }

  public handleNavigation(): void {
    this.router.navigate([this.getNavigationURL()], {}).then();
  }

  private getNavigationURL(): string {
    if (this.isRelatedPage) {
      return `${ROUTES.learn}/${ROUTES.content}/${this.content.type}/${this.content.id}`;
    } else {
      if (this.currentPageId) {
        return this.router.url.replace(this.currentPageId.toString(), this.content.id.toString());
      } else {
        const queryStartIndex: number = this.router.url.indexOf('?');
        let url: string =
          queryStartIndex > 0 ? this.router.url.slice(0, queryStartIndex) : this.router.url;

        if (url.includes('/overview')) {
          url = url.replace('/overview', '');
        } else if (url.includes('mineral-intro')) {
          url = url.replace('mineral-intro', `categories/${mineralIntroIdConst}`);
        }

        return `/${url}/${ROUTES.content}/${this.content.type.toLowerCase()}/${this.content.id}`;
      }
    }
  }
}
