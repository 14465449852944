import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ICONS_FOLDER } from '@widgets/icons/const';
import { Icon } from '@widgets/icons/types';
import { InlineSVGModule } from 'ng-inline-svg-2';

export type IconColor = 'purple' | 'blue' | 'green' | 'red' | 'base-red' | 'gray' | 'white' | 'light' | 'yellow' | null;

export type IconSize = 'xs' | 'sm' | 'xsm' | 'md' | 'lg' | 'xl' | 'xxl';

@Component({
    selector: '[ec-icon-wrapper]',
    templateUrl: './icon-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [InlineSVGModule],
})
export class IconWrapperComponent {
  @HostBinding('class') classes = 'ec-icon-wrapper';

  @Input() icon!: Icon | string;

  @Input() size!: IconSize;

  @Input() touchSize: IconSize = this.size;

  @Input() color!: IconColor;

  private readonly folder: string = ICONS_FOLDER;

  get source(): string {
    return `${this.folder}${this.icon}.svg`;
  }
}
