import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AccountService } from '@services/account';
import { noDataValue } from '@utils/helpers/date.helper';

const currencyFormats = ['income', 'invested'];
const decimalFormats = ['nma'];
const percentageFormats = ['roi', 'royalty', 'nri'];
const cumulativeFormats = ['gas', 'oil'];

@Pipe({
    name: 'digit',
    pure: false,
    standalone: true,
})
export class DigitPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private readonly accountService: AccountService,
  ) {}

  getShowMoney(): boolean {
    return !!this.accountService.account.show_money;
  }

  transform(
    value: number | string,
    format?: 'income' | 'invested' | 'nma' | 'roi' | 'royalty' | 'gas' | 'oil' | 'nri' | null,
    floatCount = 4,
  ): any {
    if (value !== null) {
      // eslint-disable-next-line no-param-reassign
      value = Number(value);
    }

    if (!value && value !== 0) {
      if (format && currencyFormats.includes(format) && !this.getShowMoney()) {
        return '$******';
      }
      return this.noDataValue(format);
    }

    if (!format) {
      return this.decimalPipe.transform(value, `1.0-${floatCount}`);
    } else {
      if (currencyFormats.includes(format)) {
        if (!this.getShowMoney()) {
          return '$******';
        }
        return this.currencyPipe.transform(value);
      } else if (percentageFormats.includes(format)) {
        if (format === 'nri') {
          return `${this.decimalPipe.transform(value, '1.0-8')}`;
        }
        return `${this.decimalPipe.transform(value, '1.2-2')}%`;
      } else if (decimalFormats.includes(format)) {
        return this.decimalPipe.transform(value, this.detectFormat(format as any));
      } else if (cumulativeFormats.includes(format)) {
        return `${this.decimalPipe.transform(value, '1.0-4')}
        ${format === 'gas' ? 'MCF' : 'BBL'}`;
      }
    }
  }

  noDataValue(format?: string | null): string {
    switch (format) {
      default:
        return noDataValue;
    }
  }

  detectFormat(format: 'nma'): string {
    switch (format) {
      case 'nma': {
        return '1.4-4';
      }
    }
  }

  kFormatter(num: number) {
    return Math.abs(num) > 999
      ? Math.sign(num) * +(Math.abs(num) / 1000).toFixed(1) + 'K'
      : Math.sign(num) * Math.abs(num);
  }
}
