import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from '@env/environment';
import { persistState } from '@datorama/akita';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ModalsSharedModule } from './app/modules/shared/modals-shared/modals-shared.module';
import { NgOtpInputModule } from 'ng-otp-input';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { WebsocketModule } from '@core/websocket';
import { AngularFireModule } from '@angular/fire/compat';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
// import { LayoutModule } from '@core/layout';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { BackendModule } from '@core/backend';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideNgxMask, NgxMaskDirective } from 'ngx-mask';
import { EcDateAdapter, EcDateParserFormatter } from '@utils/adapters/date-picker.adapter';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlatformInterceptor } from '@core/backend/services/platform.interceptor';
import { CsrfInterceptor } from '@core/backend/services/csrf.interceptor';
import { RefreshTokenInterceptor } from '@core/backend/services/refresh-token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

persistState();

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      environment.production ? [] : AkitaNgDevtools.forRoot(),
      BackendModule.forRoot({
        apiUrl: environment.apiUrl,
      }),
      AkitaNgRouterStoreModule,
      // LayoutModule,
      NgSelectModule,
      NgxMaskDirective,
      ToastrModule.forRoot(),
      NgbModule,
      AngularFireModule.initializeApp({
        apiKey: 'AIzaSyBs43rfuUlF8dEs-09OiY4QtJArxc54d9o',
        authDomain: 'eckard-app.firebaseapp.com',
        projectId: 'eckard-app',
        storageBucket: 'eckard-app.appspot.com',
        messagingSenderId: '927777770347',
        appId: '1:927777770347:web:20ca001a1b1293ae211a2e',
      }),
      WebsocketModule.config({
        url: environment.ws,
      }),
      AngularFireMessagingModule,
      NgOtpInputModule,
      ModalsSharedModule,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlatformInterceptor,
      multi: true,
    },
    { provide: NgbDateAdapter, useClass: EcDateAdapter },
    { provide: NgbDateParserFormatter, useClass: EcDateParserFormatter },
    provideNgxMask({ dropSpecialCharacters: true }),
    provideAnimations(),
  ],
}).catch(err => console.error(err));
