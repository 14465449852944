import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMenuItem } from '@core/menu';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'a[ec-navigation-item]',
    templateUrl: './navigation-item.component.html',
    styleUrls: ['./navigation-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        RouterLinkActive,
        RouterLink,
    ],
})
export class NavigationItemComponent {
  @Input() menuItem!: Pick<IMenuItem, 'route' | 'child' | 'externalLink'>;
}
