import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreadcrumbsService, IBreadcrumb } from '@services/breadcrumbs';
import { Observable } from 'rxjs';
import { RouterLink } from '@angular/router';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'ec-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        RouterLink,
        AsyncPipe,
    ],
})
export class BreadcrumbComponent {
  public breadcrumbs$: Observable<IBreadcrumb[]> = this.breadcrumbsService.breadcrumbs$;

  constructor(private readonly breadcrumbsService: BreadcrumbsService) {}
}
