import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsModalComponent } from '@widgets/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../../../widgets/buttons/components/button/button.component';
import { TextComponent } from '../../../../widgets/typography/components/text/text.component';
import { ModalHeaderComponent } from '../../../../widgets/modal/components/modal-header/modal-header.component';

@Component({
    selector: 'ec-complete-registration',
    templateUrl: './complete-registration.modal.html',
    styleUrls: ['./complete-registration.modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalHeaderComponent,
        TextComponent,
        ButtonComponent,
    ],
})
export class CompleteRegistrationModal extends AbsModalComponent {
  constructor(public activeModal: NgbActiveModal) {
    super(activeModal);
  }
}
