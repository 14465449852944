import { IAddress } from './account.interface';
import { AccreditationStatus, UserStatus } from '@services/account';

interface LinkedAccountDto {
  id: number;
  account_id: string;
  account_type: string;
  account_name: string;
  invested: string;
  income: string;
  roi: string;
  addresses: IAddress[];
  use_contact_address: boolean;
  shared_with?: AccountInvitationListSerializer[];
  bank_accounts: IBankAccounts[];
  title: string;
}

export interface IBankAccounts {
  account_number: string;
  account_subtype: string;
  account_type: string;
  institution_name: string;
  status: string;
}

interface AccountInvitationListSerializer {
  id: number;
  contact_name: string;
  contact_email: string;
  status: 'Invited' | 'Pending' | 'Viewer';
  relationship: string;
}

interface AccountsSummaryDTO {
  invested: string;
  total_income: string;
  total_roi: string;
  nma: string;
  projects_count: number;
  this_month_income: string;
}

interface SplitedSummary {
  accounts_income: string;
  shared_accounts_income: string;
  total_income: string;
  accounts_invested: string;
  shared_accounts_invested: string;
  total_invested: string;
  accounts_roi: string;
  shared_accounts_roi: string;
  total_roi: string;
}

interface BulkInviteAccountMemberDto {
  first_name: string;
  last_name: string;
  email: string;
  relation: number;
  accounts: number[];
}

interface AcceptAccountInviteDto {
  status: AcceptAccountInviteStatus;
  contact: InvitedContactSerializer;
}

enum AcceptAccountInviteStatus {
  ACCEPTED = 'ACCEPTED',
  SIGN_UP_REQUIRED = 'SIGN_UP_REQUIRED',
  LOGIN_REQUIRED = 'LOGIN_REQUIRED',
  INVALID_OR_EXPIRED = 'INVALID_OR_EXPIRED',
}

interface InvitedContactSerializer {
  id: number;
  email: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  primary_phone_format: 'Undefined' | 'Mobile' | 'Landline';
  app_status: UserStatus;
  accreditation_status: AccreditationStatus;
  is_accredited_investor: boolean;
}

export {
  LinkedAccountDto,
  AccountsSummaryDTO,
  BulkInviteAccountMemberDto,
  AcceptAccountInviteDto,
  AcceptAccountInviteStatus,
  InvitedContactSerializer,
  AccountInvitationListSerializer,
  SplitedSummary
};
