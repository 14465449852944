import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LearnRoutingModule } from './learn-routing.module';
import { LearnOverviewComponent, LearnPage } from './pages';

import { RecentUpdatesWModule } from '@widgets/recent-updates';
import {
  LearnRecentUpdatesComponent,
  LearnFeaturedStoriesComponent,
  LearnCategoriesWrapperComponent,
  LearnCategoryItemsComponent,
} from './components';
import { ContentWModule } from '@widgets/content';



import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { CallToActionModule } from '@widgets/call-to-action';

import { HomeModule } from '../home';

@NgModule({
    imports: [
    CommonModule,
    LearnRoutingModule,
    RecentUpdatesWModule,
    ContentWModule,
    InfiniteScrollModule,
    CallToActionModule,
    HomeModule,
    LearnPage,
    LearnRecentUpdatesComponent,
    LearnFeaturedStoriesComponent,
    LearnCategoriesWrapperComponent,
    LearnCategoryItemsComponent,
    LearnOverviewComponent,
],
})
export class LearnModule {}
