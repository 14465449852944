import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItems } from '@core/menu';
import { AbsComponentWithEnvironmentData } from '@base/abs';
import { SocialLinksComponent } from '../../../../widgets/social/components/social-links/social-links.component';
import { IconWrapperComponent } from '../../../../widgets/icons/components/icon-wrapper/icon-wrapper.component';
import { AndroidAppComponent } from '../../../../widgets/apps/components/android-app/android-app.component';
import { IosAppComponent } from '../../../../widgets/apps/components/ios-app/ios-app.component';
import { FooterContactBoxComponent } from './components/footer-contact-box/footer-contact-box.component';
import { DividerComponent } from '../../../../widgets/dividers/components/divider/divider.component';
import { FooterMenuItemComponent } from './components/footer-menu-item/footer-menu-item.component';
import { FooterPartnerBoxComponent } from './components/footer-partner-box/footer-partner-box.component';

@Component({
    selector: 'ec-web-footer',
    templateUrl: './web-footer.component.html',
    styleUrls: ['./web-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FooterPartnerBoxComponent,
        FooterMenuItemComponent,
        DividerComponent,
        FooterContactBoxComponent,
        IosAppComponent,
        AndroidAppComponent,
        IconWrapperComponent,
        SocialLinksComponent,
    ],
})
export class WebFooterComponent extends AbsComponentWithEnvironmentData {
  @Input() menuItems!: MenuItems | null;
}
