import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon } from '@widgets/icons/types';
import { IconWrapperComponent } from '../../../icons/components/icon-wrapper/icon-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'no-data-list',
    template: `
    <div class="row">
      <div class="col-12 text-center">
        <div class="no-data {{ size }}" [style.max-width.px]="maxWidth">
          <div class="no-data--icon" *ngIf="icon">
            <div ec-icon-wrapper [icon]="icon"></div>
          </div>
          <div class="no-data--title" *ngIf="heading">
            {{ heading }}
          </div>
          <div class="no-data--text" *ngIf="text">
            {{ text }}
          </div>
          <div class="no-data--action">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
    styleUrls: ['./no-data-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, IconWrapperComponent],
})
export class NoDataListComponent {
  @Input() icon!: Icon | string;

  @Input() heading!: string;

  @Input() text!: string;

  @Input() maxWidth = 260;

  @Input() size: 'default' | 'large' = 'default';
}
