import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
  Input,
  ElementRef,
  HostListener,
} from '@angular/core';
import { ColorPalette, Sizes } from '@core/branding';
import { ecCreateLogger } from '@utils/logger';
import { LoadingService } from '@core/backend/services/loading.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgIf, AsyncPipe } from '@angular/common';

const log = ecCreateLogger('core:branding::buttons');

@Component({
    selector: '[ec-button]',
    template: `
    <ng-container
      *ngIf="
        (loadingService.loadingState$ | async) && isLoadingBtn && thisTargetClicked;
        else contentTmp
      "
    >
      <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
      Loading...
    </ng-container>
    <ng-template #contentTmp>
      <ng-content></ng-content>
    </ng-template>
  `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AsyncPipe],
})
export class ButtonComponent implements OnInit {
  @HostBinding('class') classes = 'ec-button';

  @Input() color: ColorPalette = 'primary';

  @Input() size: Sizes = 'md';

  logger = { log };

  thisTargetClicked!: boolean;

  private _outlined!: boolean;

  private _isLoadingBtn!: boolean;

  private _disableOnRequest!: boolean;

  constructor(
    private element: ElementRef<HTMLButtonElement>,
    public readonly loadingService: LoadingService,
  ) {}

  @Input()
  get outlined() {
    return this._outlined;
  }

  set outlined(value: any) {
    this._outlined = coerceBooleanProperty(value);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  get disableOnRequest() {
    return this._disableOnRequest;
  }

  set disableOnRequest(value: any) {
    this._disableOnRequest = coerceBooleanProperty(value);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input()
  get isLoadingBtn() {
    return this._isLoadingBtn;
  }

  set isLoadingBtn(value: any) {
    this._isLoadingBtn = coerceBooleanProperty(value);
  }

  @HostListener('click') clicked(): void {
    this.thisTargetClicked = true;
    setTimeout(() => (this.thisTargetClicked = false), 0);
  }

  ngOnInit(): void {
    this.generateStyles();

    this.loadingService.loadingState$.subscribe((isLoading: boolean) => {
      if (this.disableOnRequest) {
        this.element.nativeElement.style.pointerEvents = isLoading ? 'none' : 'auto';
        this.element.nativeElement.style.opacity = isLoading ? '0.75' : '1';
      }
    });
  }

  generateStyles(): void {
    let classNames: string[] = ['btn'];

    if (this.outlined) {
      classNames.push('btn-outline');
    }

    const colorsStyles: string[] = this.generateColors();

    const sizeStyles: string[] = this.generateSizes();

    classNames = [...classNames, ...colorsStyles, ...sizeStyles];

    this.logger.log(`${ButtonComponent.name} - classNames = ${classNames}`);
    this.logger.log(this.element.nativeElement);

    this.element.nativeElement.classList.add(...classNames);
  }

  private generateSizes(): string[] {
    this.logger.log(`${ButtonComponent.name} - size = ${this.size}`);

    const classNames = [];
    switch (this.size) {
      case 'std':
        classNames.push('btn-std');
        break;
      case 'lg':
        classNames.push('btn-lg');
        break;
      case 'sm':
        classNames.push('btn-sm');
        break;
    }
    return classNames;
  }

  private generateColors(): string[] {
    this.logger.log(`${ButtonComponent.name} - color = ${this.color}`);

    const classNames = [];
    switch (this.color) {
      case 'primary':
        classNames.push('btn-primary');
        break;
      case 'accent':
        classNames.push('btn-accent');
        break;
      case 'white':
        classNames.push('btn-white');
        break;
      case 'danger':
        classNames.push('btn-danger');
        break;
    }
    return classNames;
  }
}
