<div class="wrapper" *ngIf="!isAuthorized || accreditationExpired">
  <div class="row">
    <div class="col-12">
      <h2>Invest in Minerals</h2>
    </div>

    <div *ngIf="!isAuthorized">
      <div class="col-12 mb-4 mb-xl-5 mt-3 mt-xl-4">
        <ec-text color="primary" size="lg" touchSize="lg">
          Oversight of all your mineral investments with Eckard. Track your minerals, get
          educational videos and podcasts directly to your phone.
        </ec-text>
      </div>

      <div class="col-12 d-flex align-items-center justify-content-center">
        <button ec-button size="sm" color="white" class="me-1" (click)="handleLoginClick()">
          Log In
        </button>
        <button ec-button size="sm" color="primary" class="ms-2" (click)="handleRegisterClick()">
          Register for Free
        </button>
      </div>
    </div>
    <div *ngIf="accreditationExpired">
      <ec-text class="mt-2" size="lg">Submit accreditation form to get access to all exclusive content</ec-text>
      <button
        [disabled]="
          !!(landingService.countDownTimer | async) ||
          !!(landingService.accreditationLinkIsLoading | async)
        "
        (click)="goToPandaDoc()"
        ec-button
        size="lg"
        color="primary"
        class="mt-4"
      >
        <span
          *ngIf="landingService.accreditationLinkIsLoading | async"
          class="spinner-border spinner-border-sm me-2"
          role="status"
          aria-hidden="true"
        ></span>
        @if(!!(landingService.countDownTimer | async)) { New Link available in
        {{ landingService.countDownTimer | async }}
        } @else { Update Accreditation }
      </button>
    </div>
  </div>
</div>
