import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextShortenerDirective } from '@widgets/typography/directives';
import { UppercasePipe } from './pipes';
import { TextComponent } from './components/text/text.component';
import { AddressBlockComponent } from './components/address-block/address-block.component';
import { PipeModule } from '../../pipe/pipe.module';

// import { HeadingComponent } from './components/heading/heading.component';

@NgModule({
    imports: [CommonModule, PipeModule, TextShortenerDirective, UppercasePipe, TextComponent, AddressBlockComponent],
    exports: [TextShortenerDirective, UppercasePipe, TextComponent, AddressBlockComponent],
})
export class TypographyModule {}
