import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMenuItem, MenuItems } from '@core/menu';
import { ID } from '@datorama/akita';
import { NavigationItemComponent } from '../navigation-item/navigation-item.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'div[ec-navigation-items]',
    templateUrl: './navigation-items.component.html',
    styleUrls: ['./navigation-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        NavigationItemComponent,
        NgIf,
    ],
})
export class NavigationItemsComponent {
  @Input() menuItems!: MenuItems | null;

  trackMenus(index: number, menuItem: IMenuItem): ID {
    return menuItem.id;
  }
}
