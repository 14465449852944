import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselWrapperComponent } from './components';
import { CarouselService } from './services';
import {
  CarouselItemDirective,
  CarouselIndicatorDirective,
  CarouselArrowDirective,
} from './directives';

import { TitleSlicePipe } from './pipes';
import { TypographyModule } from '@widgets/typography';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
    imports: [CommonModule, TypographyModule, CarouselModule, CarouselWrapperComponent,
    CarouselItemDirective,
    CarouselIndicatorDirective,
    CarouselArrowDirective,
    TitleSlicePipe],
    providers: [CarouselService],
    exports: [CarouselWrapperComponent],
})
export class CustomCarouselModule {}
