import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstNamePipe, LastNamePipe, ShortNamePipe, AccountPipe } from './pipes';
import { AccreditationBadgeComponent } from './components';


import { TypographyModule } from '@widgets/typography';

const PIPES = [FirstNamePipe, LastNamePipe, ShortNamePipe, AccountPipe];

@NgModule({
    imports: [CommonModule, TypographyModule, ...PIPES, AccreditationBadgeComponent],
    exports: [...PIPES, AccreditationBadgeComponent],
})
export class AccountWModule {}
