import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  BadgePositions,
  BadgeSizes,
  BadgeVariants,
} from '@widgets/badge/interfaces/badge.interface';

@Directive({
    selector: '[badge]',
    standalone: true,
})
export class BadgeDirective implements OnChanges, OnDestroy {
  @Input() badge!: any;

  @Input() badgeSize: BadgeSizes = 'medium';

  @Input() position: BadgePositions = 'top-right';

  @Input() customBadgeClasses: string | null = null;

  @Input() variant: BadgeVariants = 'primary';

  badgeElement: HTMLElement | null = null;

  constructor(private render: Renderer2, private elRef: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('badge' in changes) {
      const value = `${changes.badge.currentValue}`;
      if (!value || +value === 0) {
        this.updateBadgeText('');
      } else if (value?.length > 0) {
        this.updateBadgeText(value);
      }
    }
  }

  ngOnDestroy() {
    if (this.badgeElement) {
      // @ts-ignore
      this.render.destroy();
    }
  }

  private updateBadgeText(value: string) {
    if (!this.badgeElement) {
      this.badgeElement = this.createBadge(value);
    } else {
      this.badgeElement.textContent = value;
    }
  }

  private createBadge(value: string): HTMLElement {
    const badgeElement = this.render.createElement('span');
    this.addClasses(badgeElement);
    badgeElement.textContent = value;
    this.render.addClass(this.elRef.nativeElement, 'badge-container');
    this.render.appendChild(this.elRef.nativeElement, badgeElement);
    return badgeElement;
  }

  private addClasses(badgeElement: HTMLElement) {
    const [vPos, hPos] = this.position.split('-');
    this.render.addClass(badgeElement, 'badge');
    this.render.addClass(badgeElement, vPos);
    this.render.addClass(badgeElement, hPos);
    if (this.customBadgeClasses) {
      const customClasses = this.customBadgeClasses.split(' ');
      customClasses.forEach(customClass => {
        this.render.addClass(badgeElement, customClass);
      });
    }
    this.render.addClass(badgeElement, this.variant);
    this.render.addClass(badgeElement, this.badgeSize);
  }
}
