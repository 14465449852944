import { Component, Input, ViewChild } from '@angular/core';
import { IContent } from '@services/learn';
import { VideoWrapperComponent } from '@widgets/video/components';
import { VideoWrapperComponent as VideoWrapperComponent_1 } from '../../../../video/components/video-wrapper/video-wrapper.component';

@Component({
    selector: 'ec-video-modal',
    templateUrl: './video-modal.component.html',
    styleUrls: ['./video-modal.component.scss'],
    standalone: true,
    imports: [VideoWrapperComponent_1],
})
export class VideoModalComponent {
  @Input() content!: IContent;

  @ViewChild(VideoWrapperComponent) videoWrapper!: VideoWrapperComponent;
}
