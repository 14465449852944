import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './components';
import { StatisticCardWrapperComponent } from './components/statistic-card-wrapper/statistic-card-wrapper.component';
import { StatisticCardItemComponent } from './components/statistic-card-wrapper/statistic-card-item/statistic-card-item.component';
import { StatisticCardComponent } from '@widgets/cards/components/statistic-card-wrapper/statistic-card/statistic-card.component';
import { DisclaimerCardComponent } from './components/statistic-card-wrapper/disclaimer-card/disclaimer-card.component';
import { BaseCardComponent } from './components/base-card/base-card.component';
import { TypographyModule } from '@widgets/typography';



@NgModule({
    imports: [CommonModule, TypographyModule, CardComponent,
    StatisticCardWrapperComponent,
    StatisticCardItemComponent,
    StatisticCardComponent,
    DisclaimerCardComponent,
    BaseCardComponent],
    exports: [
        CardComponent,
        StatisticCardWrapperComponent,
        StatisticCardComponent,
        StatisticCardItemComponent,
        DisclaimerCardComponent,
        BaseCardComponent,
    ],
})
export class CardsModule {}
