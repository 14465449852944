import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IContentSectionProps } from '../../interfaces';
import { IconWrapperComponent } from '../../../icons/components/icon-wrapper/icon-wrapper.component';
import { LinkComponent } from '../../../buttons/components/link/link.component';
import { SectionLabelDirective } from '../../directives/section-label.directive';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'ec-content-section',
    templateUrl: './content-section.component.html',
    styleUrls: ['./content-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        SectionLabelDirective,
        NgClass,
        LinkComponent,
        IconWrapperComponent,
    ],
})
export class ContentSectionComponent {
  @Input() props: IContentSectionProps;

  @Input() isMain = false;

  @Output() actionClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  get label(): string {
    return this.props.content?.label ?? this.props.label;
  }

  get count(): string | undefined {
    return this.props.badge?.label;
  }

  get isZero(): boolean {
    if (this.props.badge?.label) {
      return +this.props.badge?.label === 0;
    }
    return false;
  }
}
