import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewAllCardComponent } from './components';
import { CardsModule } from '@widgets/cards';



@NgModule({
    imports: [CommonModule, CardsModule, ViewAllCardComponent],
    exports: [ViewAllCardComponent],
})
export class MobileModule {}
