import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbsComponentWithLoading } from '@base/abs';
import { ContentService, GetContentDto } from '@services/content';
import { Observable } from 'rxjs';
import { IContent } from '@services/learn';
import { map, takeUntil } from 'rxjs/operators';
import { IPaginatedResponse } from '@base/interfaces';
import { IBadgeCalculationResponse } from '@widgets/content-sections';
import { ID } from '@datorama/akita';
import { changeLoadingState, loadingStatePipe } from '@utils/loading';
import { ResponsiveService } from '@services/responsive';
import { ContentCardComponent } from '../../../../widgets/content/components/content-card/content-card.component';
import { NgClass, NgFor, AsyncPipe } from '@angular/common';
import { SpinnerWrapperComponent } from '../../../../widgets/spinner/components/spinner-wrapper/spinner-wrapper.component';

@Component({
    selector: 'ec-learn-category-items',
    templateUrl: './learn-category-items.component.html',
    styleUrls: ['./learn-category-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SpinnerWrapperComponent,
        NgClass,
        NgFor,
        ContentCardComponent,
        AsyncPipe,
    ],
})
export class LearnCategoryItemsComponent extends AbsComponentWithLoading implements OnInit {
  @Input() categoryId!: ID | undefined;

  @Input() isLocked!: boolean;

  paginationDto: GetContentDto = new GetContentDto({ page: 1, page_size: 3 });

  items$!: Observable<IContent[]>;

  verticalScrolling$: Observable<boolean> = this.responsiveService.isMobile$;

  constructor(
    private readonly contentService: ContentService,
    private readonly responsiveService: ResponsiveService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.items$ = this.contentService
      .getAll({
        ...this.paginationDto,
        categories: this.categoryId,
      })
      .pipe(
        loadingStatePipe(() => changeLoadingState(this)),
        map(({ results }: IPaginatedResponse<IContent> & IBadgeCalculationResponse) => results),
        takeUntil(this.destroyed$),
      );
  }

  trackByItem(index: number, item: IContent): ID {
    return item.id;
  }
}
