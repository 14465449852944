import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../../../widgets/buttons/components/button/button.component';
import { SuccessNotificationComponent } from '../../../../widgets/notification/components/success-notification/success-notification.component';
import { ModalHeaderComponent } from '../../../../widgets/modal/components/modal-header/modal-header.component';

@Component({
    selector: 'ec-is-blocked-error',
    templateUrl: './is-blocked-error.modal.html',
    styleUrls: ['./is-blocked-error.modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ModalHeaderComponent, SuccessNotificationComponent, ButtonComponent]
})
export class IsBlockedErrorModal {
  @Input() text: string;

  constructor(public activeModal: NgbActiveModal) {}
}
