import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { TextComponent } from '../../../../typography/components/text/text.component';

@Component({
    selector: 'disclaimer-card',
    template: `
    <div class="wrapper">
      <div class="label">
        <ec-text color="primary" weight="600">
          {{ label }}
        </ec-text>
      </div>
      <div class="item">
        <ec-text color="primary">
          <ng-content></ng-content>
        </ec-text>
      </div>
    </div>
  `,
    styleUrls: ['./disclaimer-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TextComponent],
})
export class DisclaimerCardComponent {
  @Input() label!: string;

  @HostBinding('class') @Input() column = 'col-12';
}
