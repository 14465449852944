import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItems } from '@core/menu';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgFor } from '@angular/common';

@Component({
    selector: 'div[ec-sub-header]',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        RouterLinkActive,
        RouterLink,
    ],
})
export class SubHeaderComponent {
  @Input() items!: MenuItems | null;
}
