import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlayIconComponent } from '../../../icons/components/play-icon/play-icon.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'div[ec-player-cover-img]',
    template: `
    <div class="cover-wrapper" [style.height]="height">
      <ng-container *ngIf="visibility === 'hidden'">
        <ec-play-icon (click)="play()" icon="play"></ec-play-icon>
        <img
          [style.border-radius.px]="borderRadius"
          class="cover-img"
          [src]="url"
          alt="img"
          [style.max-width]="maxWidth"
        />
      </ng-container>

      <div class="cover-video" [style.visibility]="visibility">
        <ng-content></ng-content>
      </div>
    </div>
  `,
    styleUrls: ['./player-cover-img.component.scss'],
    standalone: true,
    imports: [NgIf, PlayIconComponent],
})
export class PlayerCoverImgComponent {
  @Input() maxWidth = '100%';

  @Input() height!: string;

  @Input() borderRadius = 0;

  @Input() url!: string;

  @Output() play$ = new EventEmitter();

  visibility: 'hidden' | 'visible' = 'hidden';

  play() {
    this.visibility = 'visible';
    this.play$.emit();
  }
}
