import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
    selector: 'div[ec-main-logo], a[ec-main-logo]',
    templateUrl: './main-logo.component.html',
    styleUrls: ['./main-logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class MainLogoComponent {
  @HostBinding('class') classes = 'main-logo';
}
