import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ButtonComponent } from '../../../../widgets/buttons/components/button/button.component';
import { SuccessNotificationComponent } from '../../../../widgets/notification/components/success-notification/success-notification.component';
import { ModalHeaderComponent } from '../../../../widgets/modal/components/modal-header/modal-header.component';

@Component({
    selector: 'ec-email-verified',
    templateUrl: './email-verified.modal.html',
    styleUrls: ['./email-verified.modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalHeaderComponent,
        SuccessNotificationComponent,
        ButtonComponent,
    ],
})
export class EmailVerifiedModal {
  @Output() continue: EventEmitter<unknown> = new EventEmitter<unknown>();
}
