import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestActionCallComponent } from './components';

import { OverrideWidthDirective } from './directives';
import { TypographyModule } from '@widgets/typography';

@NgModule({
    imports: [CommonModule, TypographyModule, InvestActionCallComponent, OverrideWidthDirective],
    exports: [InvestActionCallComponent],
})
export class CallToActionModule {}
