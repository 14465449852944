import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuTriggerState } from '@core/menu';
import { Icon } from '@widgets/icons';
import { IconWrapperComponent } from '../../../../widgets/icons/components/icon-wrapper/icon-wrapper.component';

@Component({
    selector: 'div[ec-menu-trigger]',
    templateUrl: './menu-trigger.component.html',
    styleUrls: ['./menu-trigger.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IconWrapperComponent],
})
export class MenuTriggerComponent {
  @Input() state: MenuTriggerState | null = MenuTriggerState.closed;

  @Output() triggerClicked: EventEmitter<unknown> = new EventEmitter<unknown>();

  get icon(): Icon {
    switch (this.state) {
      case MenuTriggerState.closed:
        return 'menu';
      case MenuTriggerState.opened:
        return 'cross';
      default:
        return 'menu';
    }
  }
}
