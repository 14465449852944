import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ec-footer-partner-box',
    templateUrl: './footer-partner-box.component.html',
    styleUrls: ['./footer-partner-box.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink],
})
export class FooterPartnerBoxComponent {
  @Input() img!: string;

  @Input() alt!: string;

  @Input() linkTo!: string;
}
