import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CategoriesSectionComponent,
  CategoryCardContentComponent,
  CategoriesMobileComponent,
  CategoriesViewWrapperComponent,
  CategoryCardOverlayComponent,
} from './components';
import { CategoryPropPipe } from './pipes';
import { CardsModule } from '../cards';

import { TypographyModule } from '@widgets/typography';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { MobileModule } from '@widgets/mobile';

import { ContentWModule } from '@widgets/content';


@NgModule({
    imports: [
    CommonModule,
    CardsModule,
    TypographyModule,
    InfiniteScrollModule,
    MobileModule,
    ContentWModule,
    CategoriesSectionComponent,
    CategoryPropPipe,
    CategoryCardContentComponent,
    CategoriesMobileComponent,
    CategoriesViewWrapperComponent,
    CategoryCardOverlayComponent,
],
    exports: [CategoriesSectionComponent, CategoriesViewWrapperComponent],
})
export class CategoriesWModule {}
