import { Injectable } from '@angular/core';
import {
  BackendService,
  BaseApiService,
  RequestFacadeModel,
  RequestModel,
  RequestType,
} from '@core/backend';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import {
  EProjectType,
  IMarketProjectList,
} from '../../market/interfaces/marketplace-project.interface';
import { IAccreditationInfo, LandingPageItem } from '../interfaces/landing.interface';
import { catchError, map } from 'rxjs/operators';

interface Link {
  link: string;
  status: string;
}

const TIMER_DISTANCE = 0.084;

@Injectable({
  providedIn: 'root',
})
export class LandingService extends BaseApiService {
  private accreditationLinkIsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private countDownTimer$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(backendService: BackendService) {
    super(backendService, 'client/company-info/landing-page');
    this.checkCounter();
  }

  get accreditationLinkIsLoading(): Observable<boolean> {
    return this.accreditationLinkIsLoading$.asObservable();
  }

  get countDownTimer(): Observable<string> {
    return this.countDownTimer$.asObservable();
  }

  checkCounter() {
    const counterStartedAt = localStorage.getItem('countDownStartedAt');
    if (counterStartedAt) {
      const now = Date.now();
      const startTime = new Date(counterStartedAt).getTime();
      const elapsedSeconds = (now - startTime) / 1000;
      if (elapsedSeconds > 300) {
        localStorage.removeItem('countDownStartedAt');
        this.countDownTimer$.next('');
      } else {
        const remainingDistance = (TIMER_DISTANCE * 3600) - elapsedSeconds;
        this.setTimer(remainingDistance);
      }
    }
  }

  setTimer(remainingSeconds: number): void {
    const endTime = Date.now() + remainingSeconds * 1000;

    const updateTimer = () => {
      const now = Date.now();
      const distance = Math.max(endTime - now, 0);

      if (distance === 0) {
        localStorage.removeItem('countDownStartedAt');
        this.countDownTimer$.next('');
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const value = `${days ? days + 'd' : ''} ${hours ? hours + 'h' : ''} ${
        minutes ? minutes + 'm' : ''
      } ${seconds ? seconds + 's' : ''}`;
      this.countDownTimer$.next(value);

      setTimeout(updateTimer, 1000); // Update every second
    };

    updateTimer();
  }

  public getLandingInfo(): Observable<LandingPageItem[]> {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl(),
    });

    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });

    return this.backendService.send(requestFacade);
  }

  public getRecentInvestProjects(): Observable<IMarketProjectList[]> {
    const request: RequestModel = new RequestModel({
      url: 'client/marketplace/projects/recent_projects/',
    });

    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });

    return this.backendService
      .send<IMarketProjectList[]>(requestFacade)
      .pipe(tap((projects: IMarketProjectList[]) => this.updateRecentProjectFields(projects)));
  }

  public requestAccreditationFormLink(): Observable<Link> {
    this.accreditationLinkIsLoading$.next(true);
    const request: RequestModel = new RequestModel<null>({
      url: 'client/account/accreditation-form-request/',
      skipNotify: true,
      shouldIndicateLoader: false,
      requestBody: null,
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.post,
      request,
    });
    return this.backendService.send<Link>(requestFacade).pipe(
      tap(link => {
        this.accreditationLinkIsLoading$.next(false);
        this.setTimer(TIMER_DISTANCE * 3600); // Start countdown
        localStorage.setItem('countDownStartedAt', new Date().toISOString());
        return link;
      }),
      catchError(error => {
        this.accreditationLinkIsLoading$.next(false);
        throw error;
      }),
    );
  }

  public changeAccreditationInfo(
    itemToChange: Partial<IAccreditationInfo>,
  ): Observable<IAccreditationInfo> {
    const request: RequestModel<any> = new RequestModel({
      url: 'client/account/user-notified/',
      skipNotify: true,
      shouldIndicateLoader: false,
      requestBody: itemToChange,
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.patch,
      request,
    });
    return this.backendService.send<IAccreditationInfo>(requestFacade).pipe(
      map(res => res),
      catchError(error => {
        throw error;
      }),
    );
  }

  public getAccreditationInfo(): Observable<IAccreditationInfo> {
    const request: RequestModel = new RequestModel<null>({
      url: 'client/account/user-notified/',
      skipNotify: true,
      shouldIndicateLoader: false,
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.backendService.send<IAccreditationInfo>(requestFacade);
  }

  private updateRecentProjectFields(projects: IMarketProjectList[]): IMarketProjectList[] {
    projects.forEach(project => {
      project.sold_nma_percentage = '100';
      project.available_nma = '0';
      project.available_units = '0';
      if (project.project_type === EProjectType.M) {
        project.price_per_nma = project.price_per_unit;
      }
    });

    return projects;
  }
}
