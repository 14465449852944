import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { MenuItems } from '@core/menu';
import { NavigationItemsComponent } from '../navigation-items/navigation-items.component';
import { HeaderUserFlowComponent } from '../header-user-flow/header-user-flow.component';
import { NgTemplateOutlet } from '@angular/common';
import { MainLogoComponent } from '../../../../widgets/logos/components/main-logo/main-logo.component';

@Component({
    selector: 'div[ec-header]',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MainLogoComponent,
        NgTemplateOutlet,
        HeaderUserFlowComponent,
        NavigationItemsComponent,
    ],
})
export class HeaderComponent {
  @HostBinding('class') classes = 'layout-header ec-header';

  @Input() menuItems!: MenuItems | null;

  @Input() isAuthorized: boolean | null = false;

  @Input() notCompletedRegistration: boolean | null = false;

  @Input() fullName!: string | null;

  @Output() register: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() login: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() logout: EventEmitter<unknown> = new EventEmitter<unknown>();
}
