enum MenuItemLabel {
  home = 'Home',
  howItWorks = 'How it works',
  company = 'Company',
  learn = 'Learn',
  overview = 'Overview',
  allCategories = 'All Categories',
  introToMinerals = 'Intro To Minerals',
  featured = 'Featured',
  recentUpdates = 'Recent Updates',
  market = 'Market',
  updates = 'Updates',
  accounts = 'Accounts',
  profile = 'My Profile',
  p2p = 'P2P Marketplace',
  myListings = 'My Listings',
  allListings = 'All Listings',
  assets = 'Assets'
}

export { MenuItemLabel };
