import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsComponentWithEnvironmentData } from '@base/abs';

@Component({
    selector: 'ec-ios-app',
    templateUrl: './ios-app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class IosAppComponent extends AbsComponentWithEnvironmentData {}
