import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMenuItem, MenuItems } from '@core/menu';
import { ID } from '@datorama/akita';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'ec-footer-menu-item',
    templateUrl: './footer-menu-item.component.html',
    styleUrls: ['./footer-menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        RouterLink,
    ],
})
export class FooterMenuItemComponent {
  @Input() menuItems!: MenuItems | null;

  trackMenus(index: number, menuItem: IMenuItem): ID {
    return menuItem.id;
  }
}
