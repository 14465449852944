import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuService } from './services';
import { MenuTriggerComponent } from './components';


@NgModule({
    imports: [CommonModule, MenuTriggerComponent],
    providers: [MenuService],
    exports: [MenuTriggerComponent],
})
export class MenuModule {}
