import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResponsiveService } from '@services/responsive';
import { Observable } from 'rxjs';
import { GetCategoriesDto } from '@services/categories';
import { map } from 'rxjs/operators';
import { CategoriesSectionComponent } from '../categories-section/categories-section.component';
import { CategoriesMobileComponent } from '../categories-mobile/categories-mobile.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'ec-categories-view-wrapper',
    templateUrl: './categories-view-wrapper.component.html',
    styleUrls: ['./categories-view-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        CategoriesMobileComponent,
        CategoriesSectionComponent,
        AsyncPipe,
    ],
})
export class CategoriesViewWrapperComponent {
  hasCustomView$: Observable<boolean> = this.responsiveService.isTouch$;

  constructor(private readonly responsiveService: ResponsiveService) {}

  get categoriesDTO$(): Observable<GetCategoriesDto> {
    return this.hasCustomView$.pipe(
      map((state: boolean) =>
        state
          ? new GetCategoriesDto({ page: 1, page_size: 3 })
          : new GetCategoriesDto({ page: 1, page_size: 5 }),
      ),
    );
  }
}
