import { Injectable } from '@angular/core';
import { AuthQuery, AuthStore } from './store';
import {BehaviorSubject, EMPTY, mergeMap, Observable, Subject, switchMap} from 'rxjs';
import { Params, Router } from '@angular/router';
import { NavigationElement } from './enums';
import {
  BackendService,
  BaseApiService,
  RequestFacadeModel,
  RequestModel,
  RequestType,
} from '@core/backend';
import { IAuthState, ICheckToken, ITokens } from '@services/auth/interfaces';
import {
  ChangePasswordDto,
  EmailValidationDto,
  ForgotPasswordDto,
  InvitationalRegisterDto,
  LoginCredentialsDto,
  LogoutDto,
  RegisterFormDto,
} from '@services/auth/dtos';
import { catchError, tap } from 'rxjs/operators';
import { BaseStatusResponse } from '@base/classes';
import { ClientFacadeService } from '@services/client';
import {
  AccountStore,
  AccreditationStatus,
  IAccount,
  IAccountStatus,
  ISignupInvitation,
  UserStatus,
} from '@services/account';
import { Location } from '@angular/common';
import { ROUTES } from '@const';
import { ROUTES as authRoutes } from '../../routing/auth/const';
import { IAuthStepperRedirectToPage, ISignupEmailResponse } from '../../routing/auth/interfaces';
import { NotificationsService } from '@services/notifications';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceService } from '@services/device-info';
import { ILoginError } from '@services/auth/interfaces/login-error.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthStepperStore } from '../../routing/auth/store';
import {CsrfService} from '@services/auth/csrf.service';
import {IsBlockedErrorModal} from '../../routing/auth/components/is-blocked-error/is-blocked-error.modal';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseApiService {
  public redirectUrl!: string;

  public verificationParams!: Params;

  public tokens$: Observable<ITokens> = this.authQuery.getTokens$;

  public loginErrBeh$: BehaviorSubject<ILoginError> = new BehaviorSubject<any>(null);

  public isAuthorized$: Observable<boolean> = this.authQuery.isAuthorized$;

  private skipLogoutNotification = false;

  public isLogInRequested$: Subject<any> = new Subject();

  constructor(
    backendService: BackendService,
    private readonly authStore: AuthStore,
    private readonly accountStore: AccountStore,
    private readonly deviceService: DeviceService,
    private readonly authStepperStore: AuthStepperStore,
    private readonly authQuery: AuthQuery,
    private readonly clientFacadeService: ClientFacadeService,
    private readonly notificationService: NotificationsService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly notificationsService: NotificationsService,
    private readonly modalService: NgbModal,
    private readonly csrfService: CsrfService
  ) {
    super(backendService, ROUTES.auth);
  }

  // API Calls
  public login(
    loginDto: LoginCredentialsDto,
    afterRegistration = false,
    invitationFromSharedAccount = false,
    redirectToPage: IAuthStepperRedirectToPage | null = null,
  ): void {
    const request: RequestModel<LoginCredentialsDto> = new RequestModel<LoginCredentialsDto>({
      url: this.getFullUrl('client/login'),
      requestBody: loginDto,
      skipNotify: true,
      skipRedirect: true,
      options: {
        withCredentials: true
      }
    });
    const requestFacade: RequestFacadeModel<LoginCredentialsDto> =
      new RequestFacadeModel<LoginCredentialsDto>({
        requestType: RequestType.post,
        request,
      });


    this.csrfService.getCsrfToken().pipe(
      switchMap(() => {
        return this.send<ITokens, LoginCredentialsDto>(requestFacade)
          .pipe(
            mergeMap(({ access, refresh }: ITokens) => {
              this.setToken(access, refresh);
              return this.trustDeviceLogin().pipe(
                tap(res => {
                  this.handleSuccess(
                    { access: res.access, refresh: res.refresh },
                    afterRegistration,
                    invitationFromSharedAccount,
                    redirectToPage,
                  );
                }),
                catchError(() => {
                  this.handleSuccess(
                    { access, refresh },
                    afterRegistration,
                    invitationFromSharedAccount,
                    redirectToPage,
                  );
                  return EMPTY;
                })
              )
            }),
            catchError((error: HttpErrorResponse) => {
              const errorObject = error.error;

              if (errorObject.is_blocked) {
                const modal = this.modalService.open(IsBlockedErrorModal, {
                  windowClass: 'modal-small',
                  backdrop: true
                });

                modal.componentInstance.text = errorObject.is_blocked[0];

                throw error;
              }

              if (errorObject.is_access_denied) {
                this.notificationService.error(errorObject.is_access_denied, 'Access denied');

                throw error;
              }

              this.loginErrBeh$.next(errorObject);
              throw error;
            }),
          )
      })
    ).subscribe();
  }

  setTrustDevice(isTrustDevice: boolean): Observable<void> {
    const request: RequestModel<void> = new RequestModel<any>({
      url: this.getFullUrl('trust-device'),
      requestBody: { trust: isTrustDevice }
    });
    const requestFacade: RequestFacadeModel<void> = new RequestFacadeModel<void>({
      requestType: RequestType.post,
      request
    });
    return this.send<any, void>(requestFacade);
  }

  trustDeviceLogin(): Observable<any> {
    const request: RequestModel<any> = new RequestModel<any>({
      url: this.getFullUrl('trust-device/login'),
      skipNotify: true,
      skipRedirect: true
    });
    const requestFacade: RequestFacadeModel<any> = new RequestFacadeModel<any>({
      requestType: RequestType.get,
      request,
    });
    return this.send<any, any>(requestFacade);
  }

  public addDevice(): void {
    this.clientFacadeService.accountService.addDevice();
  }

  public checkEmail(emailValidationDto: EmailValidationDto): Observable<ISignupEmailResponse> {
    return this.clientFacadeService.accountService.checkEmail(emailValidationDto);
  }

  public register(registerFormDto: RegisterFormDto): Observable<IAccount> {
    return this.clientFacadeService.accountService.register(registerFormDto);
  }

  public registerWithInvitation(registerDto: InvitationalRegisterDto): Observable<IAccount> {
    return this.clientFacadeService.accountService.registerWithInvitation(registerDto);
  }

  public resendVerification(): Observable<unknown> {
    return this.clientFacadeService.accountService.resendVerification();
  }

  public forgotPassword(forgotPasswordDto: ForgotPasswordDto): Observable<BaseStatusResponse> {
    return this.clientFacadeService.clientAuthService.forgotPassword(forgotPasswordDto);
  }

  public changePassword(changePasswordDto: ChangePasswordDto): Observable<unknown> {
    return this.clientFacadeService.clientAuthService.changePassword(changePasswordDto);
  }

  public checkToken(token: string): Observable<ICheckToken> {
    return this.clientFacadeService.clientAuthService.checkToken(token);
  }

  public refreshAccessToken(): Observable<ITokens> {
    const { refresh } = this.authQuery.getValue();
    const logoutDto: LogoutDto = new LogoutDto({ refresh });
    const request: RequestModel<LogoutDto> = new RequestModel<LogoutDto>({
      url: this.getFullUrl('refresh'),
      requestBody: logoutDto,
    });

    const requestFacade: RequestFacadeModel<LogoutDto> = new RequestFacadeModel<LogoutDto>({
      requestType: RequestType.post,
      request,
    });

    return this.send<ITokens, LogoutDto>(requestFacade).pipe(
      tap(data => {
        this.setToken(data.access, data.refresh);
      }),
    );
  }

  public logout(withoutRedirect?: boolean): void {
    this.isLogInRequested$.next(false);
    this.skipLogoutNotification = true;
    const { refresh } = this.authQuery.getValue();
    const logoutDto: LogoutDto = new LogoutDto({ refresh });
    const request: RequestModel<LogoutDto> = new RequestModel<LogoutDto>({
      url: this.getFullUrl('logout'),
      requestBody: logoutDto,
      skipNotify: true,
    });

    const requestFacade: RequestFacadeModel<LogoutDto> = new RequestFacadeModel<LogoutDto>({
      requestType: RequestType.post,
      request,
    });

    this.send<unknown, LogoutDto>(requestFacade).subscribe({
      next: () => {
        this.authStore.reset();
        this.clientFacadeService.accountService.logout();
        if (!withoutRedirect) {
          void this.router.navigate([`/${ROUTES.auth}`]);
        }
      },
      error: () => {
        if (!withoutRedirect) {
          void this.router.navigate([`${ROUTES.home}`]);
        }
        this.authStore.reset();
        this.clientFacadeService.accountService.logout();
      },
    });
  }

  public killSession(): void {
    this.authStore.reset();
    this.accountStore.reset();
    if (!this.skipLogoutNotification) {
      this.redirectUrl = `${window.location.pathname}${window.location.search}`;
    }
    void this.router.navigate([`/${ROUTES.auth}/login`]);
    this.modalService.dismissAll();
    if (!this.skipLogoutNotification) {
      this.notificationsService.error('Your session has expired. Please log in.', 'SESSION');
    }
  }

  public getInvitationDetails$(token: string): Observable<ISignupInvitation> {
    return this.clientFacadeService.accountService.getInvitationDetails$(token);
  }

  // Business Logic
  public isAuthorized(): boolean {
    const tokens: ITokens = this.authQuery.getValue();
    return !!tokens?.access;
  }

  public getAccess(): Pick<IAuthState, 'access'> {
    const { access } = this.authQuery.getValue();
    return { access };
  }

  public getUserAccreditationStatus(): Pick<IAccount, 'accreditation_status'> {
    const { accreditation_status } =
      this.clientFacadeService.accountService.getAccreditationStatus();
    return { accreditation_status };
  }

  public getAccount(): IAccount {
    return this.clientFacadeService.accountService.account;
  }

  public getAccount$(): Observable<IAccount> {
    return this.clientFacadeService.accountService.account$;
  }

  public navigate(element: NavigationElement): void {
    void this.router.navigate([`/${ROUTES.auth}/${element}`]);
  }

  public back(): void {
    this.location.back();
  }

  public setToken(access: string, refresh: string) {
    this.authStore.addTokens({ access, refresh });
    this.backendService.setHeader({ Authorization: `Bearer ${access}` });
  }

  public checkForWelcomeScreenNavigation({
    accreditation_status,
    app_status,
    approval_screen_shown,
  }: Pick<IAccount, 'accreditation_status' | 'app_status'> &
    Pick<IAccountStatus, 'approval_screen_shown'>): void {
    const accredited: boolean =
      app_status === UserStatus.appUser && accreditation_status === AccreditationStatus.accredited;
    if (accredited && !approval_screen_shown) {
      this.router.navigate([`/${ROUTES.auth}/${authRoutes.approvalScreen}`]).then();
    }
    if (accredited && approval_screen_shown) {
      this.router.navigate(['/']).then();
    } else if (accreditation_status === AccreditationStatus.not_accredited) {
      this.router.navigate(['/']).then();
    }
  }

  updateProfile() {
    this.clientFacadeService.accountService.getAccountProfile$().subscribe();
  }

  public handleSuccess(
    { access, refresh }: ITokens,
    afterRegistration = false,
    invitationFromSharedAccount = false,
    redirectToPage: IAuthStepperRedirectToPage | null = null,
    impersonate = false,
  ): void {
    this.isLogInRequested$.next(true);
    this.skipLogoutNotification = false;
    this.setToken(access, refresh);
    this.clientFacadeService.accountService.getAccountProfile$().subscribe(account => {
      if (invitationFromSharedAccount && redirectToPage) {
        void this.router.navigate(
          [redirectToPage.url],
          redirectToPage.queryParams ? { queryParams: redirectToPage.queryParams } : undefined,
        );
        this.authStepperStore.reset();
        return;
      }
      this.authStepperStore.reset();
      if (account.is_two_factor_enabled && !account.is_login_verified) {
        void this.router.navigate(['/auth/mfa-verification']);
        return;
      }

      if (impersonate) {
        void this.router.navigate(['/profile/contact-info']);
        return;
      }
      this.clientFacadeService.accountService.addDevice();
      this.clientFacadeService.accountService.getAccountStatus$().subscribe(accountStatus => {
        const accredited: boolean =
          account.app_status === UserStatus.appUser &&
          account.accreditation_status === AccreditationStatus.accredited;
        const userStatusesForRedirect: UserStatus[] = [UserStatus.pendingEmail];
        const accreditationStatusesForRedirect: AccreditationStatus[] = [
          AccreditationStatus.form_pending,
          AccreditationStatus.pending,
        ];

        if (!afterRegistration) {
          this.checkForWelcomeScreenNavigation({
            accreditation_status: accountStatus.accreditation_status,
            app_status: accountStatus.app_status,
            approval_screen_shown: accountStatus.approval_screen_shown,
          });

          if (account.is_login_verified && (accredited || this.redirectUrl)) {
            void this.router.navigateByUrl(this.redirectUrl);
            this.redirectUrl = '';
            return;
          }

          if (
            userStatusesForRedirect.includes(account.app_status) ||
            accreditationStatusesForRedirect.includes(account.accreditation_status)
          ) {
            this.verificationNavigate();
          }
        } else {
          if (!accredited) {
            this.verificationNavigate();
          } else {
            void this.router.navigate([`/${ROUTES.auth}/${authRoutes.approvalScreen}`]);
          }
        }
      });
    });
  }

  private verificationNavigate(): void {
    this.router
      .navigate([`/${ROUTES.auth}/${authRoutes.accountVerification}`], {
        queryParams: this.verificationParams?.token ? { ...this.verificationParams } : null,
      })
      .then();
  }

  public checkPassword(data: { check: string }): Observable<any> {
    const request: RequestModel<any> = new RequestModel({
      url: this.getFullUrl('client/check'),
      requestBody: data,
      skipNotify: true
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.post,
      request,
    });
    return this.send<any>(requestFacade);
  }

}
