import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsModalComponent } from '@widgets/modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingService } from '../../../../routing/landing/services/landing.service';
import { Observable, take, tap } from 'rxjs';
import { ResponsiveService } from '../../../../services/responsive';
import { NgIf, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '../../../../widgets/buttons/components/button/button.component';
import { TextComponent } from '../../../../widgets/typography/components/text/text.component';
import { ModalHeaderComponent } from '../../../../widgets/modal/components/modal-header/modal-header.component';

@Component({
    selector: 'ec-accreditation-expired',
    templateUrl: './accreditation-expired.modal.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ModalHeaderComponent,
        TextComponent,
        ButtonComponent,
        NgIf,
        AsyncPipe,
    ],
})
export class AccreditationExpiredModal extends AbsModalComponent {

  isMobile$: Observable<boolean> = this.responsiveService.isMobile$;

  constructor(
    public activeModal: NgbActiveModal,
    public landingService: LandingService,
    private readonly responsiveService: ResponsiveService,
  ) {
    super(activeModal);
    this.landingService
      .changeAccreditationInfo({ is_notified_expired_today: true })
      .pipe(take(1))
      .subscribe();
  }

  goToPandaDoc(): void {
    this.landingService.requestAccreditationFormLink().pipe(
      take(1),
      tap(response => {
        window.open(response.link, '_blank');
      }),
    ).subscribe();
  }
}
