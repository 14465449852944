const ROUTES = {
  default: '',
  home: 'home',
  auth: 'auth',
  overview: 'overview',
  categories: 'categories',
  mineralIntro: 'mineral-intro',
  recentUpdates: 'recent-updates',
  relatedContent: 'related-content',
  featuredStories: 'featured-stories',
  howItWorks: 'how-it-works',
  company: 'company',
  learn: 'learn',
  content: 'content',
  market: 'market',
  landing: 'landing',
  updates: 'updates',
  profile: 'profile',
  accounts: 'accounts',
  accountInvitation: 'account-invitation',
  p2p: 'p2p-marketplace',
  myListings: 'my-listings',
  openListings: 'open-listings',
  myBids: 'my-bids',
  unsubscribe: 'unsubscribe',
  assets: 'assets'
};

export { ROUTES };
