import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'ec-footer-contact-box',
    templateUrl: './footer-contact-box.component.html',
    styleUrls: ['./footer-contact-box.component.scss'],
    standalone: true,
    imports: [NgClass],
})
export class FooterContactBoxComponent {
  @Input() heading!: string;

  @Input() wrapperClass = '';
}
