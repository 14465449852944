import { Injectable } from '@angular/core';
import {
  AccountQuery,
  AccountStore,
  AddDeviceDto,
  EditProfileDto,
  EmailVerificationDto,
  IMFAPhone,
  ISignupInvitation,
  SetPasswordDto,
  LinkedAccountDto,
  AccountsSummaryDTO,
  BulkInviteAccountMemberDto,
  AcceptAccountInviteDto,
  AccreditationStatus,
} from '@services/account';
import { BackendService, RequestFacadeModel, RequestModel, RequestType } from '@core/backend';
import { IAccount, IAccountStatus, SplitedSummary } from '@services/account/interfaces';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, switchMap, throwError } from 'rxjs';
import { ClientService } from '@services/client';
import { EmailValidationDto, InvitationalRegisterDto, RegisterFormDto } from '@services/auth';
import { ISignupEmailResponse } from '../../routing/auth/interfaces';
import { NotificationsService } from '@services/notifications';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceService } from '@services/device-info';
import { ID } from '@datorama/akita';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { TableResponse } from '@widgets/table/shared/ds-meta';
import { CsrfService } from '@services/auth/csrf.service';
import { BasePaginationDTO } from '../../../base';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends ClientService {
  errorsBeh$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  impersonateBeh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public fullName$: Observable<string> = this.accountQuery.fullName$;

  public account$: Observable<IAccount> = this.accountQuery.account$;

  public impersonate$: Observable<boolean> = this.impersonateBeh$.asObservable();

  public errors$: Observable<string[]> = this.errorsBeh$.asObservable();

  private accreditationLink!: string | undefined;

  public linkedAccountsMap: Map<number, LinkedAccountDto> = new Map();

  public transferMethodsCount$ = new BehaviorSubject<number>(0);

  constructor(
    backendService: BackendService,
    private readonly deviceService: DeviceService,
    private readonly accountStore: AccountStore,
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly notificationsService: NotificationsService,
    private readonly csrfService: CsrfService,
  ) {
    super(backendService, 'account');
  }

  get account(): IAccount {
    return this.accountQuery.getValue();
  }

  get accountWarningVisibilityWithSession(): Observable<boolean> {
    return this.accountQuery.registrationFlowWarningVisibility$(true);
  }

  get accountWarningVisibility(): Observable<boolean> {
    return this.accountQuery.registrationFlowWarningVisibility$(false);
  }

  updateNotification(unseen_updates: boolean) {
    this.accountStore.update({ ...this.account, unseen_updates });
  }

  updateCardCount(count: number) {
    this.accountStore.update({ ...this.account, count_cart: count });
  }

  hideAccountWarning(): void {
    sessionStorage.setItem('hideRegistrationWarning', 'true');
    this.accountStore.update(this.account);
  }

  showAccountWarning(): void {
    sessionStorage.setItem('hideRegistrationWarning', 'false');
    this.accountStore.update(this.account);
  }

  // API Calls
  public checkEmail(emailValidationDto: EmailValidationDto): Observable<ISignupEmailResponse> {
    const request: RequestModel<EmailValidationDto> = new RequestModel<EmailValidationDto>({
      url: this.getFullUrl('signup-email'),
      requestBody: emailValidationDto,
    });
    const requestFacade: RequestFacadeModel<EmailValidationDto> =
      new RequestFacadeModel<EmailValidationDto>({
        requestType: RequestType.post,
        request,
      });
    return this.send<ISignupEmailResponse, EmailValidationDto>(requestFacade);
  }

  public register(registerFormDto: RegisterFormDto): Observable<IAccount> {
    const request: RequestModel<RegisterFormDto> = new RequestModel<RegisterFormDto>({
      url: this.getFullUrl('signup'),
      requestBody: registerFormDto,
      options: {
        withCredentials: true,
      },
    });
    const requestFacade: RequestFacadeModel<RegisterFormDto> =
      new RequestFacadeModel<RegisterFormDto>({
        requestType: RequestType.post,
        request,
      });

    return this.csrfService.getCsrfToken().pipe(
      switchMap(() => {
        return this.send<IAccount, RegisterFormDto>(requestFacade);
      }),
    );
  }

  public registerWithInvitation(registerDto: InvitationalRegisterDto): Observable<IAccount> {
    const request: RequestModel<InvitationalRegisterDto> =
      new RequestModel<InvitationalRegisterDto>({
        url: this.getFullUrl('signup-invitation'),
        requestBody: registerDto,
        options: {
          withCredentials: true,
        },
      });
    const requestFacade: RequestFacadeModel<InvitationalRegisterDto> =
      new RequestFacadeModel<InvitationalRegisterDto>({
        requestType: RequestType.post,
        request,
      });

    return this.csrfService.getCsrfToken().pipe(
      switchMap(() => {
        return this.send<IAccount, InvitationalRegisterDto>(requestFacade);
      }),
    );
  }

  public verifyEmail(token: string): Observable<unknown> {
    const request: RequestModel<EmailVerificationDto> = new RequestModel<EmailVerificationDto>({
      url: this.getFullUrl('verify-email'),
      skipNotify: true,
      requestBody: { token },
    });
    const requestFacade: RequestFacadeModel<EmailVerificationDto> =
      new RequestFacadeModel<EmailVerificationDto>({
        requestType: RequestType.put,
        request,
      });
    return this.send<unknown, EmailVerificationDto>(requestFacade);
  }

  public resendVerification(): Observable<unknown> {
    const request: RequestModel<unknown> = new RequestModel<unknown>({
      url: this.getFullUrl('resent-email-verification'),
      requestBody: {},
    });
    const requestFacade: RequestFacadeModel<unknown> = new RequestFacadeModel<unknown>({
      requestType: RequestType.put,
      request,
    });
    return this.send<unknown, unknown>(requestFacade);
  }

  public getInvitationDetails$(token: string): Observable<ISignupInvitation> {
    const request: RequestModel = new RequestModel<null>({
      url: this.getFullUrl('signup-invitation'),
    }).withQuery<{ token: string }>({ token });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<ISignupInvitation, null>(requestFacade);
  }

  public getAccountProfile$(): Observable<IAccount> {
    const request: RequestModel = new RequestModel<null>({
      url: this.getFullUrl('profile'),
      requestBody: null,
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<IAccount, null>(requestFacade).pipe(
      tap((account: IAccount) => {
        this.accountStore.update(account);
        const hideRegistrationWarning = sessionStorage.getItem('hideRegistrationWarning');
        if (!hideRegistrationWarning) {
          sessionStorage.setItem('hideRegistrationWarning', 'false');
        }
      }),
    );
  }

  public getMFAPhone(): Observable<IMFAPhone> {
    const request: RequestModel = new RequestModel<null>({
      url: this.getFullUrl('mfa/phone'),
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });

    return this.send<IMFAPhone, null>(requestFacade);
  }

  public getAccountProfile(isImpersonate = false): void {
    this.getAccountProfile$().subscribe(account => {
      if (isImpersonate) {
        return;
      }
      if (
        !account.approval_screen_shown &&
        account.accreditation_status === AccreditationStatus.accredited
      ) {
        void this.router.navigate(['/auth/approval-screen']);
      }
    });
  }

  public getAccountStatus$(): Observable<IAccountStatus> {
    const request: RequestModel = new RequestModel<null>({
      url: this.getFullUrl('status'),
    });
    const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<IAccountStatus, null>(requestFacade);
  }

  public getAccountStatus(): void {
    this.getAccountStatus$().subscribe();
  }

  public updatePassword(setPasswordDto: SetPasswordDto): Observable<unknown> {
    const request: RequestModel<SetPasswordDto> = new RequestModel<SetPasswordDto>({
      url: this.getFullUrl('set-password'),
      requestBody: setPasswordDto,
      options: {
        withCredentials: true,
      },
    });
    const requestFacade: RequestFacadeModel<SetPasswordDto> =
      new RequestFacadeModel<SetPasswordDto>({
        requestType: RequestType.put,
        request,
      });

    return this.csrfService.getCsrfToken().pipe(
      switchMap(() => {
        return this.send<unknown, SetPasswordDto>(requestFacade).pipe(
          tap(() => {
            this.clearErrors();

            this.notificationsService.success(
              'Your password was changed successfully!',
              'Password Change',
            );
          }),
          catchError((error: HttpErrorResponse) => {
            const errorObject = error.error;
            const keys: string[] = Object.keys(errorObject);
            if (keys.length) {
              this.errorsBeh$.next(keys.map(key => errorObject[key]));
            }
            return throwError(() => error);
          }),
        );
      }),
    );
  }

  public updateProfile(profileDto: FormData): Observable<unknown> {
    const request: RequestModel<any> = new RequestModel<any>({
      url: this.getFullUrl('profile'),
      requestBody: profileDto,
      successMessage: {
        message: 'Updated',
        title: 'Profile',
      },
    });
    const requestFacade: RequestFacadeModel<any> = new RequestFacadeModel<any>({
      requestType: RequestType.put,
      request,
    });
    return this.send<unknown, EditProfileDto>(requestFacade).pipe(
      tap(() => this.clearErrors()),
      catchError((error: HttpErrorResponse) => {
        const errorObject = error.error;
        const keys: string[] = Object.keys(errorObject);
        if (keys.length) {
          this.errorsBeh$.next(keys.map(key => errorObject[key]));
        }
        return throwError(() => error);
      }),
      finalize(() => this.getAccountStatus()),
      finalize(() => this.getAccountProfile()),
    );
  }

  public addDevice(): void {
    const deviceID: ID = this.deviceService.deviceQuery.getDeviceId();
    const request: RequestModel<AddDeviceDto> = new RequestModel<AddDeviceDto>({
      url: this.getFullUrl('device'),
      requestBody: new AddDeviceDto({ registration_id: deviceID }),
      skipNotify: true,
      skipRedirect: true,
    });
    const requestFacade: RequestFacadeModel<AddDeviceDto> = new RequestFacadeModel<AddDeviceDto>({
      requestType: RequestType.post,
      request,
    });
    this.send<unknown, AddDeviceDto>(requestFacade).subscribe(() => this.testNotification());
  }

  public approvalScreenShownUpdate(): void {
    const request: RequestModel<Pick<IAccountStatus, 'approval_screen_shown'>> = new RequestModel<
      Pick<IAccountStatus, 'approval_screen_shown'>
    >({
      url: this.getFullUrl('status'),
      requestBody: { approval_screen_shown: true },
    });
    const requestFacade: RequestFacadeModel<Pick<IAccountStatus, 'approval_screen_shown'>> =
      new RequestFacadeModel<Pick<IAccountStatus, 'approval_screen_shown'>>({
        requestType: RequestType.put,
        request,
      });
    this.send<unknown, Pick<IAccountStatus, 'approval_screen_shown'>>(requestFacade).subscribe();
  }

  public testNotification(): void {
    if (!environment.production) {
      const request: RequestModel = new RequestModel<null>({
        url: this.getFullUrl('test-notification'),
        requestBody: null,
      });
      const requestFacade: RequestFacadeModel = new RequestFacadeModel<null>({
        requestType: RequestType.post,
        request,
      });
      this.send<unknown, null>(requestFacade).subscribe();
    }
  }

  public updateMoneyVisibility(): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: this.getFullUrl('money-visibility'),
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.post,
      request,
    });
    return this.send<Partial<LinkedAccountDto>[]>(requestFacade);
  }

  // Business Logic
  public logout(): void {
    this.impersonateBeh$.next(false);
    localStorage.removeItem('impersonate');
    this.showAccountWarning();
    this.accountStore.reset();
  }

  public clearErrors(): void {
    this.errorsBeh$.next([]);
  }

  public getAccreditationStatus(): Pick<IAccount, 'accreditation_status'> {
    const { accreditation_status } = this.accountQuery.getValue();
    return { accreditation_status };
  }

  public getUserStatus(): Pick<IAccount, 'app_status'> {
    const { app_status } = this.accountQuery.getValue();
    return { app_status };
  }

  public getLinkedAccounts(params?: any): Observable<TableResponse<LinkedAccountDto>> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: 'client/portfolio/accounts/linked/',
    });
    if (params) {
      request.withQuery(params);
    }
    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<TableResponse<LinkedAccountDto>>(requestFacade);
  }

  public getLinkedAccountsSummary(): Observable<AccountsSummaryDTO> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: 'client/portfolio/accounts/',
    });
    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<AccountsSummaryDTO>(requestFacade);
  }

  public sendInviteAccountMember(
    inviteAccountMember: BulkInviteAccountMemberDto,
    accountId?: number,
  ): Observable<any> {
    const request: RequestModel<BulkInviteAccountMemberDto> =
      new RequestModel<BulkInviteAccountMemberDto>({
        url: accountId
          ? `client/portfolio/accounts/${accountId}/invite/`
          : 'client/portfolio/accounts/bulk_invite/',
        requestBody: inviteAccountMember,
      });
    const requestFacade: RequestFacadeModel<BulkInviteAccountMemberDto> =
      new RequestFacadeModel<BulkInviteAccountMemberDto>({
        requestType: RequestType.post,
        request,
      });
    return this.send<any, BulkInviteAccountMemberDto>(requestFacade);
  }

  public acceptInviteAccount(token: string): Observable<AcceptAccountInviteDto> {
    const request: RequestModel<{ token: string }> = new RequestModel<{
      token: string;
    }>({
      url: `client/portfolio/accounts/accept/?token=${token}`,
    });
    const requestFacade: RequestFacadeModel<{ token: string }> = new RequestFacadeModel<{
      token: string;
    }>({
      requestType: RequestType.post,
      request,
    });
    return this.send<AcceptAccountInviteDto, any>(requestFacade);
  }

  public getSharedAccounts(params?: any): Observable<TableResponse<LinkedAccountDto>> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: 'client/portfolio/accounts/shared/',
    });

    if (params) {
      request.withQuery(params);
    }

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<TableResponse<LinkedAccountDto>>(requestFacade);
  }

  public revokeInvitation(accountId: number, invitationId: number): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: `client/portfolio/accounts/${accountId}/invitations/${invitationId}/`,
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.delete,
      request,
    });
    return this.send<any>(requestFacade);
  }

  public resendInvitation(accountId: number, invitationId: number): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: `client/portfolio/accounts/${accountId}/invitations/${invitationId}/resend/`,
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.post,
      request,
    });
    return this.send<any>(requestFacade).pipe(
      tap(() => {
        this.notificationsService.success('Your invite was resend successfully!', 'Send Invite');
      }),
    );
  }

  public getLinkedAccountsShort(): Observable<Partial<LinkedAccountDto>[]> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: 'client/portfolio/accounts/short/',
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<Partial<LinkedAccountDto>[]>(requestFacade);
  }

  public getSharedAccountsShort(): Observable<Partial<LinkedAccountDto>[]> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: 'client/portfolio/accounts/shared/short/',
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<Partial<LinkedAccountDto>[]>(requestFacade);
  }

  public getAccountsProjectDetails(accountId: string, project_id: number): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: `client/portfolio/accounts/${accountId}/projects/${project_id}/`,
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<any>(requestFacade);
  }

  public getAccountsProjectDetailsInfo(accountId: string, project_id: number): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: `client/portfolio/accounts/${accountId}/projects/${project_id}/info/`,
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<any>(requestFacade);
  }

  public getwells(accountId: string, type = 'all'): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: `client/portfolio/accounts/${accountId}/wells/?project_type=${type}`,
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<any>(requestFacade);
  }

  public getProjectWells(
    accountId: number,
    projectId: number,
    status: string,
    page_size = 1,
  ): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: `client/portfolio/accounts/${accountId}/projects/${projectId}/wells/?page_size=${page_size}&status=${status}`,
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<any>(requestFacade);
  }

  public getContsctTransferMethodsCount(): Observable<number> {
    return this.getContsctTransferMethods().pipe(
      map(response => {
        response.results.forEach((method: any) => {
          if (!method.bank_account_status) {
            return;
          }
          const changedAccount = this.linkedAccountsMap.get(method.id);
          if (changedAccount) {
            this.linkedAccountsMap.set(method.id, {
              ...changedAccount,
              bank_accounts: [
                {
                  account_number: method.bank_account_number,
                  account_subtype: method.transfer_type,
                  account_type: method.account_type,
                  institution_name: method.bank_name,
                  status: method.bank_account_status,
                },
              ],
            });
          }
        });
        this.transferMethodsCount$.next(response.count);
        return response.count;
      }),
    );
  }

  public getContsctTransferMethods(page = 1, page_size = 100): Observable<any> {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl('transfer-methods'),
    }).withQuery<BasePaginationDTO>({ page, page_size });

    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.get,
      request,
    });

    return this.backendService.send<any, any>(requestFacade);
  }

  public accountBancConnectionsSetFailed(accountId: number): Observable<any> {
    const request: RequestModel = new RequestModel({
      url: this.getFullUrl(`transfer-methods/${accountId}/set_failed`),
    });

    const requestFacade: RequestFacadeModel = new RequestFacadeModel({
      requestType: RequestType.post,
      request,
    });

    return this.backendService.send<any, any>(requestFacade);
  }

  public removeAccountTransferMethod(accountId: number): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: this.getFullUrl(`transfer-methods/${accountId}`),
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.delete,
      request,
    });
    return this.send<any>(requestFacade);
  }

  public getSplitedSummary(): Observable<SplitedSummary> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: 'client/portfolio/accounts/split-summary/',
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<SplitedSummary>(requestFacade);
  }

  public getAllProjects(): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: 'client/portfolio/projects/?page_size=500',
    });

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<any>(requestFacade);
  }

  public getAllProjectsSummary(params: any): Observable<any> {
    const request: RequestModel<null> = new RequestModel<null>({
      url: 'client/portfolio/projects/summary/',
    }).withQuery(params);

    const requestFacade: RequestFacadeModel<null> = new RequestFacadeModel<null>({
      requestType: RequestType.get,
      request,
    });
    return this.send<any>(requestFacade);
  }
}
