import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AuthService } from '@services/auth';
import { Observable, take, tap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingService } from '../../../../routing/landing/services/landing.service';
import { AccountService, AccreditationStatus } from '@services/account';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../../buttons/components/button/button.component';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'div[ec-unlock-content]',
  templateUrl: './unlock-content.component.html',
  styleUrls: ['./unlock-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, ButtonComponent, RouterLink, AsyncPipe],
})
export class UnlockContentComponent {
  @Input() overlay!: boolean;

  @Input() whiteOverlay!: boolean;

  @Input() position: 'center' | 'bottom' = 'center';

  isAuthorized$: Observable<boolean> = this.authService.isAuthorized$;

  AccreditationStatus = AccreditationStatus;

  constructor(
    public modalService: NgbModal,
    public accountService: AccountService,
    private readonly authService: AuthService,
    public landingService: LandingService,
  ) {}

  goToPandaDoc(): void {
    this.landingService
      .requestAccreditationFormLink()
      .pipe(
        take(1),
        tap(response => {
          window.open(response.link, '_blank');
        }),
      )
      .subscribe();
  }

  @HostBinding('class') get className(): string {
    const defaultClass = 'host';
    if (this.overlay) {
      return `${defaultClass} ${defaultClass}--fh`;
    } else {
      return `${defaultClass} ${defaultClass}--hh`;
    }
  }
}
