import { Directive, ElementRef } from '@angular/core';
import { CarouselService } from '../services';
import { noop } from 'rxjs';

@Directive({
    selector: '.carousel-item',
    standalone: true,
})
export class CarouselItemDirective {
  constructor(
    public readonly element: ElementRef<HTMLDivElement>,
    private readonly carouselService: CarouselService,
  ) {
    this.element.nativeElement.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
    this.element.nativeElement.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
  }

  private handleMouseEnter(): void {
    this.carouselService.resetTimer();
  }

  private handleMouseLeave(): void {
    noop();
  }
}
