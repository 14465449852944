import {Injectable, signal} from '@angular/core';
import {MenuService} from '../../menu/services';
import {BehaviorSubject, Observable} from 'rxjs';
import {MenuItems, MenuTriggerState} from '../../menu';
import {AuthService, NavigationElement} from '@services/auth';
import {ILayoutConfiguration} from '@core/layout';
import {DEFAULT_LAYOUT_CONFIGURATION} from '../const';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {ecCreateLogger} from '@utils/logger';
import {AccountService, AccreditationStatus, IAccount, UserStatus} from '@services/account';
import {NameProcessing} from '@widgets/account';
import {ACCOUNT_ITEM, GUEST_MENU_ITEMS, MENU_ITEMS, P2P_ITEM} from '@core/menu/const';
import {ResponsiveService} from '../../../services/responsive';

const log = ecCreateLogger('core:branding::layout-configuration');

@Injectable({providedIn: 'root',})
export class LayoutService {
  public tabSyncChannel = new BroadcastChannel('tabSyncChannel');

  public account$: Observable<IAccount> = this.accountService.account$;

  public impersonate$: Observable<boolean> = this.accountService.impersonate$;

  public hasContainerBeh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public hasContainer$: Observable<boolean> = this.hasContainerBeh$.asObservable();

  public accreditationExpired = signal<boolean>(false);

  public isDocusignAccreditationSigned = signal<boolean>(false);

  public menuItems$: Observable<MenuItems> = this.account$.pipe(
    mergeMap(account =>
      this.menuService.menuItems$.pipe(
        map(() => {
          if (!account.id) {
            return GUEST_MENU_ITEMS;
          }
          if (account.accreditation_status === AccreditationStatus.accredited) {
            if (account.accreditation_expired) {
              return [...GUEST_MENU_ITEMS, ...ACCOUNT_ITEM];
            }
            if (account.has_psa_and_funds_active_investments) {
              return [...MENU_ITEMS, ...ACCOUNT_ITEM, ...P2P_ITEM];
            }
            return [...MENU_ITEMS, ...ACCOUNT_ITEM];
          } else if (account.app_status !== UserStatus.notAppUser) {
            return [...GUEST_MENU_ITEMS, ...ACCOUNT_ITEM];
          } else {
            return GUEST_MENU_ITEMS;
          }
        }),
      ),
    ),
  );

  public subHeaderItems$: Observable<MenuItems> = this.menuService.subHeaderItems$;

  public triggerState$: Observable<MenuTriggerState> = this.menuService.triggerState$;

  public subHeaderActive$: Observable<boolean> = this.menuService.subHeaderActive$;

  public isAuthorized$: Observable<boolean> = this.authService.isAuthorized$;

  public fullName$: Observable<string> = this.accountService.fullName$;

  public firstName$: Observable<string> = this.fullName$.pipe(
    map((fullName: string) => {
      const nameProcessing: NameProcessing = new NameProcessing({
        full_name: fullName,
      });
      return nameProcessing.firstName ?? 'Guest';
    }),
  );

  logger = { log };

  public layoutConfigurationBeh$: BehaviorSubject<ILayoutConfiguration> =
    new BehaviorSubject<ILayoutConfiguration>(DEFAULT_LAYOUT_CONFIGURATION);

  public layoutConfiguration$: Observable<ILayoutConfiguration> =
    this.layoutConfigurationBeh$.asObservable();

  public hasBreadcrumbs$: Observable<boolean> = this.layoutConfiguration$.pipe(
    map((configuration: ILayoutConfiguration) => !!configuration.elements.breadcrumbs),
  );

  public hasWrapper$: Observable<boolean> = this.layoutConfiguration$.pipe(
    map((configuration: ILayoutConfiguration) => !!configuration.elements.wrapper),
  );

  public hasFooter$: Observable<boolean> = this.layoutConfiguration$.pipe(
    map((configuration: ILayoutConfiguration) => !!configuration.elements.footer),
  );

  constructor(
    private readonly menuService: MenuService,
    private readonly authService: AuthService,
    public readonly accountService: AccountService,
    private readonly responsiveService: ResponsiveService,
  ) {}

  public handleMenuTriggerClick(): void {
    this.menuService.handleMenuTriggerClick();
  }

  public setContainerValue(value: boolean): void {
    this.hasContainerBeh$.next(value);
  }

  public setConfiguration(layoutConfiguration: ILayoutConfiguration): void {
    this.logger.log(`Layout configuration changing - ${JSON.stringify(layoutConfiguration)}`);
    this.layoutConfigurationBeh$.next(layoutConfiguration);
  }

  public navigate(element: NavigationElement): void {
    this.responsiveService.isTouch$
      .pipe(
        take(1),
        tap(isTouch => {
          this.authService.navigate(element);
          if (isTouch) {
            this.handleMenuTriggerClick();
          }
        }),
      )
      .subscribe();
  }
}
