import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CarouselService } from '@widgets/carousel/services/carousel.service';
import { Observable } from 'rxjs';
import { ISliderButton, ISliderItem, LinkToE } from '@services/slider';
import {
  CarouselArrowDirective,
  CarouselIndicatorDirective,
  CarouselItemDirective,
} from '@widgets/carousel/directives';
import { ID } from '@datorama/akita';
import { AbsBaseComponent } from '@base/abs';
import { takeUntil } from 'rxjs/operators';
import { ArrowAction } from '@widgets/carousel/enums';
import { typeWrapper } from '@utils/types';
import { ContentType } from '@services/learn';
import { Router } from '@angular/router';
import { ResponsiveService } from '@services/responsive';
import { OwlOptions, CarouselModule } from 'ngx-owl-carousel-o';
import { CarouselArrowDirective as CarouselArrowDirective_1 } from '../../directives/carousel-arrow.directive';
import { ButtonComponent } from '../../../buttons/components/button/button.component';
import { WatchVideoComponent } from '../../../buttons/components/watch-video/watch-video.component';
import { TextComponent } from '../../../typography/components/text/text.component';
import { CarouselItemDirective as CarouselItemDirective_1 } from '../../directives/carousel-item.directive';
import { CarouselIndicatorDirective as CarouselIndicatorDirective_1 } from '../../directives/carousel-indicator.directive';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'ec-carousel-wrapper',
    templateUrl: './carousel-wrapper.component.html',
    styleUrls: ['./carousel-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        CarouselIndicatorDirective_1,
        NgClass,
        CarouselItemDirective_1,
        TextComponent,
        WatchVideoComponent,
        ButtonComponent,
        CarouselArrowDirective_1,
        CarouselModule,
        AsyncPipe,
    ],
})
export class CarouselWrapperComponent extends AbsBaseComponent implements AfterViewInit {
  @Input() items: ISliderItem[] | null = [];

  @ViewChildren(CarouselItemDirective)
  slides!: QueryList<CarouselItemDirective>;

  @ViewChildren(CarouselArrowDirective)
  arrows!: QueryList<CarouselArrowDirective>;

  @ViewChildren(CarouselIndicatorDirective)
  indicators!: QueryList<CarouselIndicatorDirective>;

  arrowAction = ArrowAction;

  public slideAction$: Observable<unknown> = this.carouselService.slideAction$;

  customOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    center: true,
    navSpeed: 1200,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };

  constructor(
    public readonly responsiveService: ResponsiveService,
    private readonly carouselService: CarouselService,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.handleSlideToSub();
    this.handleArrowsSub();
    this.carouselService.startTimer();
    this.slideAction$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.nextSlideTrigger());
  }

  public isVideo(item: ISliderItem): boolean {
    return item.type === ContentType.video;
  }

  handleAction(button: ISliderButton): void {
    switch (button.link_to) {
      case LinkToE.LOG_IN_FORM:
        this.router.navigate(['/auth/login']).then();
        break;
      case LinkToE.SIGN_UP_FORM:
        this.router.navigate(['/auth/register']).then();
        break;
      case LinkToE.MEDIA_CATEGORY:
        this.router.navigate([`/learn/categories/${button.url}`]).then();
        break;
      case LinkToE.MEDIA:
        this.router.navigate([`/learn/content/article/${button.url}`]).then();
        break;
      case LinkToE.URL:
        window.open(button.url, '_blank');
        break;
    }
  }

  private handleSlideToSub(): void {
    this.carouselService.slideTo$.pipe(takeUntil(this.destroyed$)).subscribe((id: ID) => {
      const activeSlide = this.findActiveElement();

      if (activeSlide) {
        this.removeActiveClass(activeSlide);
      }

      const slide = this.slides.find(
        (s: CarouselItemDirective) => s.element.nativeElement.getAttribute('data-slide') === id,
      );

      if (slide) {
        this.setActiveClass(slide, id as number);
      }
    });
  }

  private handleArrowsSub(): void {
    this.carouselService.navigateTo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((action: ArrowAction) => {
        const activeSlide = this.findActiveElement();

        if (activeSlide) {
          let valueToSearch: number;
          const currentIndex = typeWrapper<number>(
            activeSlide.element.nativeElement.getAttribute('data-slide'),
          );

          this.removeActiveClass(activeSlide);

          this.handleSpecificCase(action, currentIndex);

          switch (action) {
            case ArrowAction.next:
              valueToSearch = +typeWrapper<number>(+currentIndex + 1);
              break;
            case ArrowAction.prev:
              valueToSearch = +typeWrapper<number>(+currentIndex - 1);
              break;
          }

          const elementToUpdate = this.slides.find((s: CarouselItemDirective) =>
            currentIndex
              ? +typeWrapper<number>(s.element.nativeElement.getAttribute('data-slide')) ===
                valueToSearch
              : true,
          );

          if (elementToUpdate) {
            this.setActiveClass(elementToUpdate, valueToSearch);
          }
        }
        this.cd.detectChanges();
      });
  }

  private handleSpecificCase(action: ArrowAction, currentIndex: number): void {
    if (+currentIndex === 0 && action === ArrowAction.prev) {
      this.setActiveClass(this.slides.last, this.slides.length - 1);
    } else if (+currentIndex === this.slides.length - 1 && action === ArrowAction.next) {
      this.setActiveClass(this.slides.first, 0);
    }
  }

  private findActiveElement(): CarouselItemDirective | undefined {
    return this.slides.find((s: CarouselItemDirective) =>
      s?.element?.nativeElement?.classList?.contains('active'),
    );
  }

  private removeActiveClass(element: CarouselItemDirective): void {
    element?.element?.nativeElement?.classList?.remove('active');
  }

  private setActiveClass(element: CarouselItemDirective, activeIndex: number): void {
    element?.element?.nativeElement?.classList?.add('active');
    this.carouselService.changeActiveItem(activeIndex);
  }

  private nextSlideTrigger(): void {
    this.arrows?.last?.element?.nativeElement?.click();
  }
}
