import { Component, HostBinding, Input } from '@angular/core';
import { AbsComponentWithLoading } from '@base/abs';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ec-spinner-wrapper',
    templateUrl: './spinner-wrapper.component.html',
    styleUrls: ['./spinner-wrapper.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class SpinnerWrapperComponent extends AbsComponentWithLoading {
  @Input() loadingState = false;

  @HostBinding('class.d-none')
  public get isHidden(): boolean {
    return !this.loadingState;
  }
}
