import { Pipe, PipeTransform } from '@angular/core';
import { ICategory } from '@services/categories';

@Pipe({
    name: 'categoryProp',
    standalone: true,
})
export class CategoryPropPipe implements PipeTransform {
  transform(value: ICategory, ...args: Array<keyof ICategory>): unknown {
    const key = args.find(Boolean) as keyof ICategory;
    return value[key];
  }
}
