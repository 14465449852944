import { Pipe, PipeTransform } from '@angular/core';
import { getStateAbbreviation } from '../../const/location/usa-states';

@Pipe({
    name: 'stateAbbr',
    standalone: true,
})
export class StateAbbrPipe implements PipeTransform {
  transform(state: string) {
    return getStateAbbreviation(state);
  }
}
