import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypographyModule } from '@widgets/typography';
import { AccreditationExpiredModal } from './components/accreditation-expired/accreditation-expired.modal';
import { RouterModule } from '@angular/router';





@NgModule({
    imports: [
    CommonModule,
    TypographyModule,
    RouterModule,
    AccreditationExpiredModal
],
    exports: [AccreditationExpiredModal],
})
export class ModalsSharedModule {}
