import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  AbsComponentWithContent,
  BasePaginationDTO,
  DEFAULT_SECTION_ACTION_LABEL,
  WithScroll,
  WithScrollHandler,
  WithSectionProps,
} from '@base/index';
import { IBadgeProps, IContentSectionProps, SectionLabel } from '../../../content-sections';
import { ContentService } from '@services/content';
import { IContent, LearnLogicService } from '@services/learn';
import { ID } from '@datorama/akita';
import { GetContentDto } from '@services/content/dtos';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { limitItems } from '@utils/data-display';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NoDataListComponent } from '../../../no-data/components/no-data-list/no-data-list.component';
import { ContentCardComponent } from '../../../content/components/content-card/content-card.component';
import { SpinnerWrapperComponent } from '../../../spinner/components/spinner-wrapper/spinner-wrapper.component';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { ContentSectionComponent } from '../../../content-sections/components/content-section/content-section.component';

@Component({
    selector: 'ec-recent-update-section',
    templateUrl: './recent-update-section.component.html',
    styleUrls: ['./recent-update-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ContentSectionComponent,
        NgIf,
        SpinnerWrapperComponent,
        NgClass,
        NgFor,
        ContentCardComponent,
        NoDataListComponent,
        InfiniteScrollDirective,
        AsyncPipe,
    ],
})
export class RecentUpdateSectionComponent
  extends AbsComponentWithContent<BasePaginationDTO>
  implements WithSectionProps, WithScroll, WithScrollHandler, OnChanges
{
  @Input() withVirtualScroll: boolean | null = false;

  @Input() contentDto!: GetContentDto | null;

  @Input() detailedView!: boolean;

  @Input() isMain = false;

  @Input() sectionProps: IContentSectionProps | null = {
    label: SectionLabel.recentUpdates,
    actionLabel: DEFAULT_SECTION_ACTION_LABEL,
  };

  content$: Observable<IContent[]> = combineLatest([
    this.content$,
    this.learnLogicService.itemsLimitationsBeh$.asObservable(),
  ]).pipe(
    map(([items, limitations]) => {
      if (limitations) {
        return limitItems<IContent>({
          results: items,
          showCount: limitations.showCount,
        });
      }
      return items;
    }),
  );

  private page = 0;

  constructor(
    contentService: ContentService,
    private readonly learnLogicService: LearnLogicService,
  ) {
    super(contentService, new BasePaginationDTO({ page: 1, page_size: 12 }), {
      showCount: undefined,
    });
  }

  get sectionProps$(): Observable<{ badge: IBadgeProps | undefined }> {
    return this.badgeProps$.pipe(
      map((badge: IBadgeProps | undefined) => ({
        ...this.sectionProps,
        badge,
      })),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.withVirtualScroll && changes.withVirtualScroll.currentValue) {
      this.getData();
    }

    if (changes.contentDto && changes.contentDto.firstChange) {
      this.recentUpdatesDTO$.next(changes.contentDto.currentValue);
      this.getData();
    }
  }

  handleActionClick(): void {
    const { most_recent, featured } = this.contentDto ?? {};
    this.contentService.viewAllRecentUpdates({ most_recent, featured });
  }

  handleScroll(): void {
    this.page++;
    const dto: BasePaginationDTO = this.recentUpdatesDTO$.getValue();
    const newDtoValue = { ...dto, page: this.page };

    this.recentUpdatesDTO$.next(newDtoValue);

    if (this.hasNext) {
      this.handleNextData();
    }
  }

  trackByContent(index: number, content: IContent): ID {
    return content.id;
  }
}
