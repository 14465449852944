import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbsComponentWithContentCheck } from '@base/abs';
import { WithContentVisibility, WithVisibility } from '@base/interfaces';
import { AuthService } from '@services/auth';
import { UnlockContentComponent } from '../unlock-content/unlock-content.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'div[ec-unlock-content-overlay]',
    templateUrl: './unlock-content-overlay.component.html',
    styleUrls: ['./unlock-content-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, UnlockContentComponent],
})
export class UnlockContentOverlayComponent extends AbsComponentWithContentCheck<WithContentVisibility> {
  @Input() state!: WithVisibility;

  @Input() whiteOverlay!: boolean;

  @Input() position: 'center' | 'bottom' = 'center';

  constructor(authService: AuthService) {
    super(authService);
  }
}
