import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsComponentWithEnvironmentData } from '@base/abs';
import { ISocialElement, SocialElements, StaticContentService } from '@services/content-facade';
import { Observable } from 'rxjs';
import { ID } from '@datorama/akita';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'ec-social-links',
    templateUrl: './social-links.component.html',
    styleUrls: ['./social-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, AsyncPipe],
})
export class SocialLinksComponent extends AbsComponentWithEnvironmentData {
  public socialElements$: Observable<SocialElements> = this.staticContentService.socialElements$;

  constructor(private readonly staticContentService: StaticContentService) {
    super();
  }

  trackSocial(index: number, socialElement: ISocialElement): ID {
    return socialElement.id;
  }
}
