import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { CardStyle } from '../../types';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: '[ec-card]',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgTemplateOutlet,
    ],
})
export class CardComponent implements OnChanges {
  @Input() view: CardStyle | undefined;

  @Input() withImage: boolean | undefined;

  @Input() customClass: string;

  @HostBinding('class') classes = 'ec-card';

  get isMinimal(): boolean {
    return this.view === 'minimal';
  }

  ngOnChanges(): void {
    this.classes = this.isMinimal
      ? this.withImage
        ? 'ec-card ec-card--minimal ec-card--minimal--image'
        : 'ec-card ec-card--minimal'
      : this.view === 'default'
      ? 'ec-card'
      : 'ec-card ec-card--detailed';
  }
}
