import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_SECTION_ACTION_LABEL } from '@base/const';
import { IconWrapperComponent } from '../../../icons/components/icon-wrapper/icon-wrapper.component';
import { LinkComponent } from '../../../buttons/components/link/link.component';
import { CardComponent } from '../../../cards/components/card/card.component';

@Component({
    selector: 'ec-view-all-card',
    templateUrl: './view-all-card.component.html',
    styleUrls: ['./view-all-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CardComponent,
        LinkComponent,
        IconWrapperComponent,
    ],
})
export class ViewAllCardComponent {
  @Input() title = DEFAULT_SECTION_ACTION_LABEL;

  @Output() clicked: EventEmitter<unknown> = new EventEmitter<unknown>();
}
