import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdownConfig, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '@services/account';
import { ShortNamePipe } from '../../../../widgets/account/pipes/short-name.pipe';
import { LastNamePipe } from '../../../../widgets/account/pipes/last-name.pipe';
import { FirstNamePipe } from '../../../../widgets/account/pipes/first-name.pipe';
import { ButtonComponent } from '../../../../widgets/buttons/components/button/button.component';
import { BadgeDirective } from '../../../../widgets/badge/directives/badge.directive';
import { RouterLink } from '@angular/router';
import { IconWrapperComponent } from '../../../../widgets/icons/components/icon-wrapper/icon-wrapper.component';
import { NgIf, AsyncPipe, TitleCasePipe } from '@angular/common';

@Component({
    selector: '[ec-header-user-flow]',
    templateUrl: './header-user-flow.component.html',
    styleUrls: ['./header-user-flow.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        IconWrapperComponent,
        RouterLink,
        BadgeDirective,
        NgbDropdown,
        NgbDropdownToggle,
        NgbDropdownMenu,
        NgbDropdownItem,
        ButtonComponent,
        AsyncPipe,
        TitleCasePipe,
        FirstNamePipe,
        LastNamePipe,
        ShortNamePipe,
    ],
})
export class HeaderUserFlowComponent {
  @Input() isAuthorized: boolean | null = false;

  @Input() notCompletedRegistration: boolean | null = false;

  @Input() fullName!: string | null;

  @Output() register: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() login: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() logout: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(public readonly accountService: AccountService, config: NgbDropdownConfig) {
    config.placement = 'bottom-right';
    config.autoClose = true;
  }
}
