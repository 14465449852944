import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsComponentWithLayoutChange } from '@base/abs';
import { BackgroundValues, LayoutService } from '@core/layout';
import { HomePage } from '../../../home/pages/home/home.page';

@Component({
    selector: 'ec-learn',
    templateUrl: './learn.page.html',
    styleUrls: ['./learn.page.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [HomePage],
})
export class LearnPage extends AbsComponentWithLayoutChange {
  constructor(layoutService: LayoutService) {
    super(layoutService, {
      background: BackgroundValues.white,
      elements: { breadcrumbs: true, wrapper: true, footer: true },
    });
  }
}
