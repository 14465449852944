import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../../../buttons/components/button/button.component';
import { NgIf } from '@angular/common';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';

@Component({
    selector: 'ec-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ModalHeaderComponent, NgIf, ButtonComponent]
})
export class ConfirmModalComponent {
  @Input() public header = '';

  @Input() public text = '';

  @Input() public cancelButtonText = 'Cancel';

  @Input() public okButtonText = 'Ok';

  @Input() public showCancelButton = true;

  @Input() public hideActions: boolean;

  constructor(public activeModal: NgbActiveModal) {}
}

