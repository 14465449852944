import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentCardComponent, ContentOverlayComponent } from './components';
import { ContentPipe } from './pipes';
import { TypographyModule } from '@widgets/typography';
import { LockIconDirective } from './directives';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';




@NgModule({
    imports: [
    CommonModule,
    TypographyModule,
    NgbPopoverModule,
    ContentCardComponent, ContentPipe, ContentOverlayComponent, LockIconDirective,
],
    exports: [ContentCardComponent, LockIconDirective],
})
export class ContentWModule {}
