import { Pipe, PipeTransform } from '@angular/core';
import { MONTH_FULL } from '@utils/helpers/date.helper';

@Pipe({
    name: 'intToMonth',
    standalone: true,
})
export class IntToMonthPipe implements PipeTransform {
  transform(value: number): unknown {
    return MONTH_FULL[value - 1];
  }
}
