import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AccountService, AccreditationStatus } from '@services/account';
import { VerificationsSentModal } from '../../../../routing/auth/modals';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take, tap } from 'rxjs/operators';
import { AbsBaseComponent } from '@base/abs';
import { AuthService } from '../../../../services/auth';
import { LandingService } from '../../../../routing/landing/services/landing.service';
import { ButtonComponent } from '../../../buttons/components/button/button.component';
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { TextComponent } from '../../../typography/components/text/text.component';
import { IconWrapperComponent } from '../../../icons/components/icon-wrapper/icon-wrapper.component';

@Component({
  selector: 'ec-accreditation-badge',
  template: `
    <div>
      <div [class.is-pending]="inactiveStatus()" class="wrapper">
        <div
          ec-icon-wrapper
          [icon]="accreditationExpired ? 'cross-circle-red' : 'verified'"
          size="lg"
          [color]="
            inactiveStatus()
              ? 'blue'
              : accreditationStatus === 'Not Accredited'
              ? 'gray'
              : accreditationExpired
              ? 'base-red'
              : null
          "
          class="me-2"
        ></div>
        <ec-text
          size="lg"
          touchSize="lg"
          [color]="accreditationStatus === 'Not Accredited' ? 'gray' : 'primary'"
          weight="600"
          class="text-capitalize"
        >
          {{ accreditationExpired ? 'Accreditation Expired' : accreditationStatus }}
        </ec-text>
      </div>
      <div *ngIf="!accreditationExpired">
        <div class="mt-2">
          <ec-text
            *ngIf="!!signingDate"
            size="md"
            touchSize="md"
            color="gray"
            weight="500"
            class="text-capitalize"
          >
            Signing Date:
          </ec-text>
          <ec-text
            size="md"
            touchSize="md"
            color="primary"
            weight="600"
            class="ms-2 text-capitalize"
          >
            {{ signingDate | date }}
          </ec-text>
        </div>
        <div *ngIf="!!expirationDate" class="mt-2">
          <ec-text size="md" touchSize="md" color="gray" weight="500" class="text-capitalize">
            Expiration Date:
          </ec-text>
          <ec-text
            size="md"
            touchSize="md"
            color="primary"
            weight="600"
            class="ms-2 text-capitalize"
          >
            {{ expirationDate | date }}
          </ec-text>
        </div>
        <div></div>
      </div>
      <ng-container>
        <button
          ec-button
          color="white"
          class="mt-3"
          isLoadingBtn
          *ngIf="accreditationExpired && !action().visibility"
          (click)="goToPandaDoc()"
          [disabled]="
            !!(landingService.countDownTimer | async) ||
            !!(landingService.accreditationLinkIsLoading | async)
          "
        >
          <span
            *ngIf="landingService.accreditationLinkIsLoading | async"
            class="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
          @if(!!(landingService.countDownTimer | async)) { New Link available in
          {{ landingService.countDownTimer | async }}
          } @else { Submit Accreditation Form }
        </button>
        <button
          ec-button
          color="white"
          class="mt-3"
          isLoadingBtn
          *ngIf="action().visibility"
          (click)="handleAction()"
          [disabled]="!!(landingService.countDownTimer | async)"
        >
          <span
            *ngIf="landingService.accreditationLinkIsLoading | async"
            class="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
          @if(!!(landingService.countDownTimer | async)) { New Link available in
          {{ landingService.countDownTimer | async }}
          } @else { {{ action().label }} }
        </button>
      </ng-container>
    </div>
  `,
  styleUrls: ['./accreditation-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconWrapperComponent, TextComponent, NgIf, ButtonComponent, AsyncPipe, DatePipe],
})
export class AccreditationBadgeComponent extends AbsBaseComponent {
  @Input() accreditationStatus!: AccreditationStatus;

  @Input() accreditationExpired!: boolean;

  @Input() signingDate: string;

  @Input() expirationDate: string;

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    public landingService: LandingService,
  ) {
    super();
  }

  goToPandaDoc(): void {
    this.landingService
      .requestAccreditationFormLink()
      .pipe(
        take(1),
        tap(response => {
          window.open(response.link, '_blank');
        }),
      )
      .subscribe();
  }

  resendEmail(): void {
    this.accountService.resendVerification().subscribe(() =>
      this.modalService.open(VerificationsSentModal, {
        windowClass: 'modal-small',
        backdrop: true,
      }),
    );
  }

  action(): { visibility: boolean; label?: string } {
    const action = {
      label: '',
      visibility: true,
    };
    switch (this.accreditationStatus) {
      case AccreditationStatus.pending_email:
        action.label = 'Resend Verification Email';
        action.visibility = true;
        break;
      case AccreditationStatus.form_pending:
        action.label = 'Submit Accreditation Form';
        action.visibility = true;
        break;
      default:
        action.visibility = false;
        break;
    }
    return action;
  }

  inactiveStatus(): boolean {
    const inactiveStatuses = [
      AccreditationStatus.pending_email,
      AccreditationStatus.pending,
      AccreditationStatus.form_pending,
    ];
    return inactiveStatuses.includes(this.accreditationStatus);
  }

  handleAction() {
    if (this.accreditationStatus === AccreditationStatus.pending_email) {
      this.resendEmail();
    } else if (this.accreditationStatus === AccreditationStatus.form_pending) {
      this.goToPandaDoc();
    }
  }
}
