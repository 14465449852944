import { Pipe, PipeTransform } from '@angular/core';
import { FileTypes } from '@utils/helpers/file-types.helper';

const documentTypes = [
  FileTypes.DOC,
  FileTypes.DOCX,
  FileTypes.PDF,
  FileTypes.XLS,
  FileTypes.XLSX,
  FileTypes.JPG,
  FileTypes.PNG,
  FileTypes.MP4,
];

@Pipe({
    name: 'documentIcon',
    standalone: true,
})
export class DocumentIconPipe implements PipeTransform {
  transform(filename: string): string | null {
    const type = filename.split('.').reverse()[0]?.toLowerCase().replace('xlsx', 'xls');
    return documentTypes.includes(type?.toLowerCase() as FileTypes)
      ? `/assets/icons/documents-${type}.svg`
      : null;
  }
}
