import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbsComponentWithEnvironmentData } from '@base/abs';

@Component({
    selector: 'ec-android-app',
    templateUrl: './android-app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class AndroidAppComponent extends AbsComponentWithEnvironmentData {}
