import {AfterViewInit, ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit,} from '@angular/core';
import {BackendService} from '@core/backend';
import {AuthService, ITokens} from '@services/auth';
import {ActivationStart, Event, NavigationEnd, Router, RouterOutlet, Scroll} from '@angular/router';
import {debounceTime, filter, mergeMap, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AbsBaseComponent} from '@base/abs';
import {BreadcrumbsService} from '@services/breadcrumbs';
import {Headers} from '@core/system';
import {ContentStore} from '@services/content/store';
import {MenuService} from '@core/menu/services';
import {WebsocketService, WsActionsE} from '@core/websocket';
import {NotificationsService} from '@services/notifications';
import {AccountService} from '@services/account';
import {DropdownService} from '@widgets/dropdown/services/dropdown.service';
import {environment} from '@env/environment';
import {UrlService} from '@services/url/url.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  AccreditationExpiredModal
} from './modules/shared/modals-shared/components/accreditation-expired/accreditation-expired.modal';
import {LandingService} from './modules/routing/landing/services/landing.service';
import {InactivityService} from '@services/auth/inactivity.service';
import {Observable} from 'rxjs';
import {LayoutService} from './modules/core/layout';
import {SwUpdate} from '@angular/service-worker';
import {LayoutWrapperComponent} from './modules/core/layout/components/layout-wrapper/layout-wrapper.component';
import {ViewportScroller} from '@angular/common';

@Component({
    selector: 'ec-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [LayoutWrapperComponent, RouterOutlet],
})
class AppComponent extends AbsBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:beforeunload')
  beforeUnloadApp() {
    this.ws.disconnect();
  }

  @HostListener('window:click')
  @HostListener('window:keypress')
  updateActivityTimer() {
    if (environment.production && !this.inactivityService.isModalOpen) {
      this.inactivityService.setInactivityTime();
    }
  }

  @HostListener('window:storage')
  storageChanges() {
    if (environment.production) {
      if (!this.inactivityService.isModalOpen) {
        this.inactivityService.setInactivityTimer();
      } else {
        this.inactivityService.checkModalView();
      }
    }
  }

  public impersonate$: Observable<boolean> = this.layoutService.impersonate$;

  constructor(
    readonly accountService: AccountService,
    private readonly breadcrumbsService: BreadcrumbsService,
    private readonly menuService: MenuService,
    private readonly ws: WebsocketService,
    private readonly notificationsService: NotificationsService,
    private readonly dropdownService: DropdownService,
    private readonly inactivityService: InactivityService,
    private readonly urlService: UrlService,
    readonly authService: AuthService,
    readonly contentStore: ContentStore,
    readonly router: Router,
    backendService: BackendService,
    private readonly modalService: NgbModal,
    private readonly landingService: LandingService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private readonly layoutService: LayoutService,
    private swUpdate: SwUpdate,
    viewportScroller: ViewportScroller
  ) {
    super();

    contentStore.reset();

    authService.tokens$.subscribe(({ access }: ITokens) => {
      if (access) {
        this.impersonate$
          .pipe(
            take(1),
            tap(impersonate => {
              this.accountService.getAccountProfile(impersonate);
            }),
          )
          .subscribe();

        backendService.setHeader({
          [Headers.authorization]: `Bearer ${access}`,
        });
      } else {
        backendService.removeHeader(Headers.authorization);
      }
    });
    router.events
      .pipe(
        filter(event => event instanceof ActivationStart || event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
      )
      .subscribe((event: Event) => {
        if (event instanceof ActivationStart) {
          const currentEvent: ActivationStart = event as ActivationStart;
          if (!currentEvent.snapshot.data.skipBreadcrumbs) {
            this.breadcrumbsService.build(currentEvent);
          }
        } else if (event instanceof NavigationEnd) {
          const currentEvent: NavigationEnd = event as NavigationEnd;
          if (!currentEvent.url.includes('learn')) {
            this.menuService.subHeaderActiveBeh$.next(false);
          }
          this.urlService.setUrls(currentEvent.url);
        }
      });
    router.events.pipe(filter((e: any): e is Scroll => e instanceof Scroll)).subscribe(scroll => {
      if (scroll.routerEvent.url.includes('?')) {
        return;
      }

      if (!router.url.includes('ordering=')) {
        setTimeout(() => {
          viewportScroller.scrollToPosition([0, 0]);
        }, 100);
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.dropdownService.hideDropdown$.next(true);
    this.dropdownService.hidePopover$.next(true);
  }

  ngOnInit(): void {
    this.handleAccreditationEvent();
    this.handleFavicon();
    this.isNotifiedAccreditationExpired();

    this.layoutService.tabSyncChannel.onmessage = () => {
      this.layoutService.isDocusignAccreditationSigned.set(true);
    };
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.layoutService.tabSyncChannel.close();
  }

  ngAfterViewInit(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(takeUntil(this.destroyed$)).subscribe(event => {
        if (event.type === 'VERSION_READY') {
          this.swUpdate.activateUpdate().then(() => {
            window.location.reload();
          });
        }
      });
    }
  }

  handleAccreditationEvent() {
    this.ngZone.runOutsideAngular(() => {
      this.ws.on().subscribe(res => {
        this.authService.updateProfile();
        this.ngZone.run(() => {
          switch (res.action) {
            case WsActionsE.ACCOUNT_ACCESS_REMOVED:
              // console.log(res);
              break;
            case WsActionsE.ACCREDITATION_REVOKED:
              void this.router.navigate(['/profile/contact-info']);
              this.notificationsService.error('ACCREDITATION REVOKED', 'Accreditation');
              break;
            case WsActionsE.ACCREDITATION_EXPIRED:
              this.openAccreditationModal();
              break;
            case WsActionsE.ACCREDITATION_FORM_ACCEPTED:
              this.layoutService.accreditationExpired.set(false);
              break;
          }
          this.cdr.detectChanges();
        });
      });
    });
  }

  private openAccreditationModal(): void {
    this.modalService.open(AccreditationExpiredModal, {
      windowClass: 'modal-medium',
      backdrop: true,
    });
  }

  private isNotifiedAccreditationExpired(): void {
    this.impersonate$
      .pipe(
        debounceTime(2000),
        takeUntil(this.destroyed$),
        filter(res => !res),
        switchMap(() => {
          return this.accountService.account$.pipe(
            take(1),
            filter(account => account.accreditation_expired === true),
            mergeMap(() => this.landingService.getAccreditationInfo()),
            tap(info => {
              if (!info.is_notified_expired_today) {
                this.openAccreditationModal();
              }
            }),
          );
        }),
      )
      .subscribe();
  }

  private handleFavicon(): void {
    if (environment.apiUrl.startsWith('https://api.stg')) {
      const favIcon: HTMLLinkElement = document.querySelector('#favIcon') as HTMLLinkElement;
      const favIcon16: HTMLLinkElement = document.querySelector('#favIcon16') as HTMLLinkElement;
      const favIcon32: HTMLLinkElement = document.querySelector('#favIcon32') as HTMLLinkElement;
      const favIcon180: HTMLLinkElement = document.querySelector('#favIcon180') as HTMLLinkElement;

      favIcon.href = './favicon-stg.ico';
      favIcon16.href = './assets/favicon/favicon-stg-16x16.png';
      favIcon32.href = './assets/favicon/favicon-stg-32x32.png';
      favIcon180.href = './assets/favicon/apple-touch-icon-stg.png';
    }
  }
}

export { AppComponent };
