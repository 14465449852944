import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CategoriesService, GetCategoriesDto } from '@services/categories';
import { AbsComponentWithCategories } from '@base/abs';
import { BasePaginationDTO } from '@base/classes';
import { IContentSectionProps, SectionLabel } from '@widgets/content-sections';
import { WithSectionPropsWithoutAction } from '@base/interfaces';
import { ViewAllCardComponent } from '../../../mobile/components/view-all-card/view-all-card.component';
import { CategoryCardContentComponent } from '../category-card-content/category-card-content.component';
import { CardComponent } from '../../../cards/components/card/card.component';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { SpinnerWrapperComponent } from '../../../spinner/components/spinner-wrapper/spinner-wrapper.component';
import { ContentSectionComponent } from '../../../content-sections/components/content-section/content-section.component';

@Component({
    selector: 'ec-categories-mobile',
    templateUrl: './categories-mobile.component.html',
    styleUrls: ['./categories-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        ContentSectionComponent,
        SpinnerWrapperComponent,
        NgFor,
        CardComponent,
        CategoryCardContentComponent,
        NgIf,
        ViewAllCardComponent,
        AsyncPipe,
    ],
})
export class CategoriesMobileComponent
  extends AbsComponentWithCategories<BasePaginationDTO>
  implements OnChanges, WithSectionPropsWithoutAction
{
  @Input() categoriesDto!: GetCategoriesDto;

  sectionProps: IContentSectionProps = {
    label: SectionLabel.categories,
  };

  constructor(categoriesService: CategoriesService) {
    super(categoriesService, new BasePaginationDTO(), { showCount: undefined });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categoriesDto && changes.categoriesDto.firstChange) {
      this.categoriesDTO$.next(changes.categoriesDto.currentValue);
      this.getData();
    }
  }

  handleActionClick(): void {
    this.categoriesService.viewAll();
  }
}
