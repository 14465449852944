import { Pipe, PipeTransform } from '@angular/core';
import { WHITESPACE } from '@const';

@Pipe({
    name: 'shortName',
    standalone: true,
})
export class ShortNamePipe implements PipeTransform {
  transform(fullName: string): string {
    if (fullName) {
      return fullName
        .split(WHITESPACE)
        .map(n => n[0])
        .join('')
        .concat('.');
    }
    return fullName;
  }
}
